import { HostListener, Injectable } from "@angular/core";
import { MeetingDomain, MeetingLite } from "../models/Meeting";
import { Participant } from "../models/Participant";
import { MeetingService } from "./meeting.service";
import { environment } from '../../environments/environment';
import { UtilService } from "./util.service";
import { MeetingSettings } from "../models/MeetingSettings";
//import { ParticipantVideoComponent } from "../components/participant-video/participant-video.component";
//import { ParticipantAudioComponent } from "../components/participant-audio/participant-audio.component";
import { Device } from "mediasoup-client";
import { Subject } from "rxjs";
import { ChatMessage } from "../models/ChatMessage";
import { ActivatedRoute } from "@angular/router";
import { RxReplicationPullStreamItem, addRxPlugin } from 'rxdb';
import { RxDBDevModePlugin } from 'rxdb/plugins/dev-mode';
import { getRxStorageDexie } from 'rxdb/plugins/storage-dexie';
import { getRxStorageMemory } from 'rxdb/plugins/storage-memory';

import { createRxDatabase } from 'rxdb/plugins/core';
//import { replicateServer } from 'rxdb-server/plugins/replication-server';
import { replicateRxCollection } from 'rxdb/plugins/replication';
import { RxDBCleanupPlugin } from 'rxdb/plugins/cleanup';
//import { debuglog } from "util";
import {Crypto} from 'crypto-js';
declare const io: any;


if(!environment.production){
  addRxPlugin(RxDBDevModePlugin);
}
addRxPlugin(RxDBCleanupPlugin);

//declare const io: any;

@Injectable({
  providedIn: 'root'
})
export class MeetingHandlerService {
  numberOfConnectionAttemptsBeforeRequestingNewServer = 20;
  //clientId: string;
  meetingid: string = '';
  meetinginfo: MeetingLite | null | undefined = new MeetingLite();
  domain: MeetingDomain | null | undefined = null;
  producerIdSocketIdMap: Map<string, string> = new Map<string, string>();
  participantsMap: Map<string, Participant> = new Map<string, Participant>();//this is a map of socketid -> participant
  //allParticipantsMap: Map<string, Participant> = new Map<string, Participant>();
  //allPeers: any = {};
  roomParticipantsMap: Map<string, Set<string>> = new Map<string, Set<string>>();
  participantsUsernameMap: Map<string, Participant> = new Map<string, Participant>();
  breakoutRooms: Map<string, Set<string>> = new Map<string, Set<string>>();//this is a map of rooms -> Set<usernames> //this is the proposed breakout...before they actually breakout
  videoConsumersMap: Map<string, any> = new Map<string, any>();//the key is producerId
  audioConsumersMap: Map<string, any> = new Map<string, any>();//the key is producerId
  enabledConsumers: Set<string> = new Set<string>();
  meParticipant = new Participant();
  //socket;
  mediasoupDevice;
  mediasoupProducerTransport;
  mediasoupConsumerTransport;
  //localMediasoupProducers = [];//using a producers array because of private producers
  //mediasoupSocketIds = {};
  localAudioTrack: MediaStreamTrack | null;
  localVideoTrack: MediaStreamTrack | null;
  //localVideoTrack: MediaStreamTrack;
  screenShareVideoTrack: MediaStreamTrack | null;
  screenShareAudioTrack: MediaStreamTrack | null;
  localuserinfo: any = {};
  audioAvailable: boolean = false;
  videoAvailable: boolean = false;
  screenShareAvailable: boolean = false;
  screenShareVideoProducerId: any;
  screenShareAudioProducerId: any;
  screenShareVideoConsumer: any;
  screenShareAudioConsumer: any;
  screenShareVideoParticipantProducerId: string | null;
  screenShareAudioParticipantProducerId: string | null;
  screenShareParticipantSocketId: string | null;
  screenShareParticipantUsername: string | null;
  screenShareParticipantName: string | null;
  participantScreenShareAvailable: boolean = false;
  leaving: boolean = false;
  selectedMicrophone;
  selectedCamera;
  production: boolean;
  ready: boolean = false;
  test: boolean = false;
  //i.e 50 plus the focused participant
  maxSpeakingParticipants: number = environment.production ? 10 : 2;
  //numberOfLoudestProducerPasses: number = 0;
  //loudestProducers: any[] = [];
  disablequietconsumersinterval: any;
  loudConsumers: Set<string> = new Set<string>();
  //domainIndex = 0;
  //usersIncremented: boolean;
  connecting = false;
  currentRoom: string | null = null;
  defaultRoom = 'Main';
  //noopinterval: any;
  heartbeatTimeout: any;
  
  producerRequested: any = {};
  addedCurrentProducers: boolean = false;
  reconnecting = false;
  handraised = false;
  cloudRecorder = false;

  allParticipantsCount = 0;
  // modifyingScreenSharePublishing = false;
  // modifyingAudioPublishing = false;
  // modifyingVideoPublishing = false;

  screenPublishingLocker: object = new Object();
  audioPublishingLocker: object = new Object();
  videoPublishLocker: object = new Object();
  
  //recording: boolean = false;
  
  //chatopen = false;
  jwt: string = '';
  // participantVideoComponentsMap: Map<string, ParticipantVideoComponent> = new Map<string, ParticipantVideoComponent>();
  // participantAudioComponentsMap: Map<string, ParticipantAudioComponent> = new Map<string, ParticipantAudioComponent>();
  chatmessages: ChatMessage[] = [];
  //don't forget to destroy these in the cleanup() method...might change it to destroy()

  audioPublishedSubject: Subject<any> = new Subject();
  audioStoppedSubject: Subject<any> = new Subject();
  videoPublishedSubject: Subject<any> = new Subject();
  videoStoppedSubject: Subject<any> = new Subject();
  participantRemovedSubject: Subject<string> = new Subject();
  participantBeingRemovedSubject: Subject<string> = new Subject();
  participantProducerRemovedSubject: Subject<any> = new Subject();
  chatAddedSubject: Subject<any> = new Subject();
  screenSharePublishedSubject: Subject<any> = new Subject();
  participantAddedSubject: Subject<Participant> = new Subject();
  screenShareVideoProducerAddedSubject: Subject<any> = new Subject();
  screenShareAudioProducerAddedSubject: Subject<any> = new Subject();
  meetingEndedSubject: Subject<any> = new Subject();
  producerTypeUnavailableSubject: Subject<any> = new Subject();
  takeAttendanceSubject: Subject<any> = new Subject();
  reconnectingSubject: Subject<any> = new Subject();
  reconnectedSubject: Subject<any> = new Subject();
  //toastrWarning: Subject<string> = new Subject();
  //toastrSuccess: Subject<string> = new Subject();
  notifySubject: Subject<any> = new Subject();
  //toastrError: Subject<string> = new Subject();
  participantHandRaisedSubject: Subject<Participant> = new Subject();
  connectedSubject: Subject<boolean> = new Subject();
  handDroppedSubject: Subject<any> = new Subject();
  //remoteProducerPausedSubject: Subject<any> = new Subject();
  //remoteProducerResumedSubject: Subject<any> = new Subject();
  videoConsumerRemovedSubject: Subject<string> = new Subject();
  audioConsumerRemovedSubject: Subject<string> = new Subject();
  screenShareVideoConsumerRemovedSubject: Subject<string> = new Subject();
  screenShareAudioConsumerRemovedSubject: Subject<string> = new Subject();
  remoteVideoProducerAddedSubject: Subject<{socketid: string, producerid: string, kind: string, private: boolean}> = new Subject();
  remoteAudioProducerAddedSubject: Subject<{socketid: string, producerid: string, kind: string, private: boolean}> = new Subject();
  enteredRoomSubject: Subject<void> = new Subject();
  customMessageSubject: Subject<{ messageId: string, socketId: string, message: any, private: boolean }> = new Subject();
  disconnectedSubject: Subject<void> = new Subject();
  peerEnteredRoomSubject: Subject<{room: string, username: string}> = new Subject();
  allParticipantsRemovedSubject: Subject<void> = new Subject();
  leaveMeetingSubject: Subject<void> = new Subject();
  unstableConnectionSubject: Subject<void> = new Subject();
  connectionRestoredSubject: Subject<void> = new Subject();
  clientInstanceId = '';
  initialised = false;
  createProducerTransportPromise: Promise<void> | null;
  createConsumerTransportPromise: Promise<void> | null;
  stopConnecting = false;
  domainConnectionAttempts = 0;
  static previewAudioLevelId = '__preview__';
  audioProducer: any = null;
  videoProducer: any = null;
  screenShareVideoProducer: any = null;
  screenShareAudioProducer: any = null;
  rxdbDb: any;
  commandSubscribers: any = {};
  outgoingRxDBMessageId = 0;
  remoteCommandsReplicationState: any;
  localCommandsReplicationState: any;
  timeBetweenHeartbeatsMs = 10000;
  //maximumHeartbeatResponseTimeMs = 5000;
  //maximumCommandTimeout = 60000;
  connectTimeoutMs = 30000; //40000 //this number needs to be significantly less than the server heartbeat timeout (minimumHeartbeatSecondsMs)
  //also, timeBetweenHeartbeatsMs + connectTimeoutMs should always be less than minimumHeartbeatSecondsMs (minimumHeartbeatSecondsMs is on the server)
  //connectTimeoutMs also serves as the default command timeout and the maximum amount of time allowed to recover in handleDisconnect()

  //static microphonedisabledbyhost: string = 'microphonedisabledbyhost';

  //if these commaands fail, a hard reconnect must be triggered because it would mean there's something critically wrong
  criticalServerCommands = new Set(['newPeer', 'newProducer']);
  commandsThatCanTimeout = new Set(['no-op', 'restartProducerTransportIce', 'restartConsumerTransportIce', 'setConsumerPreferredSpatialLayer', 'setConsumerPriority', 'closeConsumersByType', 'consumeAdd', 'pauseConsumer', 'resumeConsumer', 'closeConsumer', 'closeProducerTransport', 'closeConsumerTransport']);
  //nonRepeatableCommands = new Set(['']);

  rxdbReplicationSocket: any = null;

  ensureNotInadvertantlyPublishingInterval: any = null;
  allowAutoReconnect: boolean = true;
  connected: boolean = false;

  initSNo = 0;

  remoteCommandsPullStream$: Subject<RxReplicationPullStreamItem<any, any>> | null;
  localCommandsPullStream$: Subject<RxReplicationPullStreamItem<any, any>> | null;
  remotelyInitiatedCommandsCollection: any;
  locallyInitiatedCommandsCollection: any;

  private selfieSegmentationCanvasElement: HTMLCanvasElement | null;
  private selfieSegmentationCanvasContext: CanvasRenderingContext2D | null;
  videoBackgroundImage: any | null;
  private selfieSegmentationCamera: any;
  selfieSegmentation: any;
  inMeetingVideoStream: MediaStream | null;
  shouldBlurBackground;
  maxCommandRetries = 2;
  
  constructor(private meetingservice: MeetingService, private util: UtilService, private settings: MeetingSettings, private route: ActivatedRoute) { 
    this.meParticipant.me = true;
    this.production = environment.production && !route.snapshot.queryParams.debug;
    this.breakoutRooms.set(this.defaultRoom, new Set<string>());
    this.maxSpeakingParticipants = this.production ? 10 : 1;
    this.generateClientInstanceId();//no need to await this because there will be no clientInstanceId set

    //we are doing this here so that the browser knows we are in a call
    //at this point, we won't have published anything
    //this.createAudioTrack();
    this.setUpSelfieSegmentation();
    //Some of these should also have a corresponding entry in destroy()
  }

  private setUpSelfieSegmentation(){    
    this.selfieSegmentation = new (window as any).SelfieSegmentation({
      locateFile: (file) => {
        //return `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/${file}`;
        return `assets/mediapipeModels/${file}`;
      }
    });
    this.selfieSegmentation.setOptions({
      modelSelection: 1,
      selfieMode: true
    });
  }
  private onSelfieSegmentationResultsAvailable(results, canvasElement, videoBackgroundImage, flipImage) {    
    try{
      let url;
      if(videoBackgroundImage)
        url = new URL(videoBackgroundImage?.src);

      if(url?.pathname !== "/null" && url?.pathname !== "" && videoBackgroundImage)
      {
        if(!this.selfieSegmentationCanvasContext){
          this.selfieSegmentation.onResults((results) => {});
          return;
        }
        this.selfieSegmentationCanvasContext.save();
        if(canvasElement?.height != results?.height){
          canvasElement.width = results.image.width;
          canvasElement.height = results.image.height;      
        }
        this.selfieSegmentationCanvasContext.clearRect(0, 0, canvasElement.width, canvasElement.height);
        if(flipImage){
          this.selfieSegmentationCanvasContext.scale(-1, 1); // Mirror horizontally
          this.selfieSegmentationCanvasContext.translate(-canvasElement.width, 0);
        }
        this.selfieSegmentationCanvasContext.drawImage(results.segmentationMask, 0, 0, canvasElement.width, canvasElement.height);
        this.selfieSegmentationCanvasContext.globalCompositeOperation = 'source-in';
        this.selfieSegmentationCanvasContext.drawImage(results.image, 0, 0, canvasElement.width, canvasElement.height);
        
        if(!this.shouldBlurBackground){
          this.selfieSegmentationCanvasContext.globalCompositeOperation = 'destination-atop';
          this.selfieSegmentationCanvasContext.drawImage(videoBackgroundImage, 0, 0, canvasElement.width, canvasElement.height);
        }
        else{        
          this.selfieSegmentationCanvasContext.globalCompositeOperation = 'destination-atop';
          this.selfieSegmentationCanvasContext.filter = 'blur(10px)';
          this.selfieSegmentationCanvasContext.drawImage(results.image, 0, 0, canvasElement.width, canvasElement.height);        
        }
        this.selfieSegmentationCanvasContext.restore();
      }else{
        this.selfieSegmentation.onResults((results) => {});
      }     
    }
    catch(e){
      console.warn('error processing canvas drawing:', e);
    }    
  }
  stopVideoStream(){
    if(this.inMeetingVideoStream){
      this.inMeetingVideoStream.getVideoTracks().forEach(t => t.stop());
      this.inMeetingVideoStream = null;
    }
  }
  public stopSelfieSegmentationCamera(){
    if(this.selfieSegmentationCamera){      
      this.selfieSegmentationCamera.stop();
      this.selfieSegmentationCamera = null;
    }
  }
  public startSelfieSegmentationCamera(video, constraint, imgSrc, canvasElement, flipImage = false){
    try{
      this.videoBackgroundImage = new Image();
      this.videoBackgroundImage.src = imgSrc;
      this.selfieSegmentationCanvasContext = canvasElement.getContext('2d');
        if(this.selfieSegmentation == null){
          this.setUpSelfieSegmentation();
        }            
      this.selfieSegmentation.onResults((results) => this.onSelfieSegmentationResultsAvailable(results, canvasElement, this.videoBackgroundImage, flipImage));
      this.stopSelfieSegmentationCamera();//load time of initiating new camera below is quicker when we stop it before creating a new instance
      this.selfieSegmentationCamera = new (window as any).Camera(video, {
        onFrame: async () => {
          if(this.selfieSegmentation)
            await this.selfieSegmentation.send({ image: video });
        },
      }, constraint);
      this.selfieSegmentationCamera.start();
      this.inMeetingVideoStream = this.selfieSegmentationCanvasElement ? this.selfieSegmentationCanvasElement.captureStream() : canvasElement.captureStream();    
    }
    catch(e){
      console.log('error starting selfieSegmentationCamera:', e);
    }
  }

  getCanvasStream(videoConstraints = null, selectedBackgroundImage = null, overrideeStream = false){
    let stream = this.checkForAvailableVideoStream();
    if(stream == null || overrideeStream || this.selfieSegmentationCanvasElement == null || this.settings.selectedBackgroundImage == null){
      let videoElement = document.createElement('video');
      videoElement.autoplay = true;
      videoElement.muted = true;
      this.selfieSegmentationCanvasElement = document.createElement('canvas');
      this.startSelfieSegmentationCamera(videoElement, videoConstraints ?? this.settings.getVideoConstraints(), selectedBackgroundImage ?? this.settings.selectedBackgroundImage, this.selfieSegmentationCanvasElement, true);
      return this.inMeetingVideoStream;
    }
    return stream;
  }
  checkForAvailableVideoStream(){
    return this.inMeetingVideoStream ? this.inMeetingVideoStream : null;   
  }
  private async generateClientInstanceId(){
    if(this.clientInstanceId){
      //we'll first make sure the server cleans up the old user
      await this.meetingservice.selfDisconnectAndDeassign(this.domain!, this.jwt, this.clientInstanceId, false);
    }
    this.clientInstanceId = (Math.random() + 1).toString(36).substring(2);
    console.log(`generated new clientInstanceId: ${this.clientInstanceId}`);
  }

  public async getMeetingServer(){
    if(this.domain){
      return this.domain;
    }
    else{
      const domain = await this.meetingservice.requestMeetingServer(this.meetingid!, this.jwt, this.clientInstanceId, false).toPromise();
      this.domain = domain;
      return domain;
    }
  }

  private async connectToMeeting(room: string | null = null, participantData: any = null) {
    //TODO: here, we should check if the meeting has ended
    console.log('attempting connect to meeting');
    
    //debugger;
    //TODO: this method should really be happening from the page where the user clicks "Enter Meeting"
    this.handraised = false;
    this.ready = false;
    //const mythis = this;
    await this.disconnect();
    

    try{
      this.domain = null;
      //we need to refresh the list before making another attempt to pick a server and connect
      this.domain = await this.getMeetingServer();

      if(!this.domain) {
        //this.meetingservice.setLookingForServer(this.meetingid, this.jwt);//notify the system that a user is in need of a server. no real need to await it
        return false;
      }
      else{
    //}
    
        await this.subscribeToMediasoupRoom(room, participantData);
        //await this.meetingservice.decrementLookingForServer(this.meetingid, this.jwt).toPromise();//do i need this if i'm just maintaining a user count? that should be sufficient to ensure we have enough servers
        //once we are able to find a server, set it on redis that the user has found a server
        //break;

        

        return true;
      }
    }
    catch(error){
      console.error(error);
      //await this.meetingservice.incrementLookingForServer(this.meetingid, this.jwt).toPromise();
      //if we are not able to find a server, set it on redis that there's a user looking for a server
    }

    return false;
  }

  async connectToMeetingUntilSuccessful(first = false, room: string | null = null, participantData: any = null, maxAttempts: number | undefined = undefined){
    this.stopConnecting = false;
    let connected = false;
    
    console.log('connect to meeting until successful called');
    if(!this.connecting){//if we're already connecting, this means that this method is being called from somewhere else...if that's the case, we don't want to run the connection loop again
      
      this.connecting = true;
      await this.generateClientInstanceId();
      //we are going to increment the user count for this meeting on redis...
      //this will be used by the fleet manager to determine how many people are trying to connect, and hence, it will know how many servers we need...
      //if we don't have enough servers, it will need to create more
      // if(!this.usersIncremented){
      //   this.meetingservice.incrementUsers(this.meetingid, this.jwt).toPromise();//no need to await  
      //   this.usersIncremented = true;
      // }
      //let domainConnectionAttempts = 0;
      if(!first){
        this.reconnectingSubject.next(null);
      }
      let attempt = 0;
      while(true)
      {
        attempt++;
        if(this.stopConnecting){
          this.stopConnecting = false;
          break;
        }
        try{
          //to make sure no individual connection attempt lasts forever
          
          connected = await this.util.runFunctionWithTimeout(() => this.connectToMeeting(room, participantData), this.connectTimeoutMs);
          if(connected){
            this.meParticipant.data = participantData;

            this.connected = true;

            this.connectedSubject.next(first);
            
            break;
          }
        }
        catch(ex){
          console.log(ex);
        }

        if(maxAttempts && attempt >= maxAttempts){
          break;
        }
        
        await this.util.sleep(5 * 1000);//every 10 seconds
        if(this.domain && this.domain.domain){
          this.domainConnectionAttempts++;
          if(this.domainConnectionAttempts % this.numberOfConnectionAttemptsBeforeRequestingNewServer == 0){
            //after a certain number of unsuccessful attempts to connect to the assigned server, we should ask for a new server by reseting the client instance id...this will make it seem like its a new instance
            this.domain = null;
            //await this.generateClientInstanceId();
          }
        }
      }
      this.connecting = false;
      if(!first){
        this.reconnectedSubject.next(null);
      }
    }
    return connected;
  }

  // async publishMedia() {
  //   //if the user is a host or we are in a meeting, we want to preemptively fire off the create producer transport
  //   if(this.meParticipant.host || (this.meetinginfo.mode == this.util.meetingmode)){
  //     this.createProducerTransportPromise = this.createProducerTransport();
  //   }
  //   await Promise.all([this.publishVideo(true), this.publishAudio(true)]);
  // }

  async destroy(stopTracks: boolean = true){
    
    //console.log('destroying handler service');
    this.leaving = true;
    

    // if(this.usersIncremented){
    //   this.meetingservice.decrementUsers(this.meetingid, this.jwt).toPromise();//no need to await
    // }

    

    if(this.heartbeatTimeout){
      clearTimeout(this.heartbeatTimeout);
      this.heartbeatTimeout = null;
    }

    if(this.disablequietconsumersinterval){
      clearInterval(this.disablequietconsumersinterval);
      this.disablequietconsumersinterval = null;
    }

    if(this.ensureNotInadvertantlyPublishingInterval){
      clearInterval(this.ensureNotInadvertantlyPublishingInterval);
      this.ensureNotInadvertantlyPublishingInterval = null;
    }

    try{
      if(stopTracks){
        [this.localAudioTrack, this.localVideoTrack, this.screenShareVideoTrack, this.screenShareAudioTrack/*, this.localVideoTrack*/].forEach((track) => {
          if(track){
            track.stop();
          }
        });
        this.stopVideoStream();
      }   
    }
    catch (e){
      console.log('error:', e)
    }   

    //this.clientId = null;
    this.meetingid = '';
    this.meetinginfo = new MeetingLite();
    this.domain = null;
    this.producerIdSocketIdMap = new Map<string, string>();
    this.participantsMap = new Map<string, Participant>();//this is a map of socketid -> participant
    this.roomParticipantsMap = new Map<string, Set<string>>();
    this.participantsUsernameMap = new Map<string, Participant>();
    this.breakoutRooms = new Map<string, Set<string>>();//this is a map of rooms -> Set<usernames> //this is the proposed breakout...before they actually breakout
    this.breakoutRooms.set(this.defaultRoom, new Set<string>());
    this.videoConsumersMap = new Map<string, any>();//the key is producerId
    this.audioConsumersMap = new Map<string, any>();//the key is producerId
    this.enabledConsumers = new Set<string>();
    this.meParticipant = new Participant();
    this.meParticipant.me = true;
    
    if(this.rxdbDb && !this.rxdbDb.destroyed){
      await this.stopRXDBReplication();
      await this.rxdbDb.destroy();
    }
    this.rxdbDb = null;
    this.commandSubscribers = {};
    this.mediasoupDevice = null;
    try{
      this.mediasoupConsumerTransport.close();
    }
    catch{}
    try{
      this.mediasoupProducerTransport.close();
    }
    catch{}
    this.selfieSegmentation = null;
    this.selfieSegmentationCanvasContext = null;
    this.selfieSegmentationCanvasElement = null;
    this.videoBackgroundImage = null;
    this.mediasoupProducerTransport = null;
    this.mediasoupConsumerTransport = null;
    //this.localMediasoupProducers = [];//using a producers array because of private producers
    //this.mediasoupSocketIds = {};
    this.localAudioTrack = null;
    this.localVideoTrack = null;
    //localVideoTrack: MediaStreamTrack;
    this.screenShareVideoTrack = null;
    this.localuserinfo = {};
    this.audioAvailable = false;
    this.videoAvailable = false;
    this.screenShareAvailable = false;
    this.screenShareVideoProducerId = null;
    this.screenShareAudioProducerId = null;
    this.screenShareVideoConsumer = null;
    this.screenShareAudioConsumer = null;
    this.screenShareVideoParticipantProducerId = null;
    this.screenShareAudioParticipantProducerId = null;
    this.screenShareParticipantSocketId = null;
    this.screenShareParticipantUsername = null;
    this.screenShareParticipantName = null;
    this.participantScreenShareAvailable = false;
   
    this.selectedMicrophone = null;
    this.selectedCamera = null;
    this.production = false;
    this.ready = false;
    this.test = false;
    // this.participantsPerShowMore = 20;
    // this.maxDisplayedParticipants = 51;//i.e 50 plus the focused participant
    
    //this.numberOfLoudestProducerPasses = 0;
    //this.loudestProducers = [];
    this.disablequietconsumersinterval = null;
    this.loudConsumers = new Set<string>();
    //this.domainIndex = 0;
    //this.usersIncremented = false;
    this.connecting = false;
    this.currentRoom = null;
    this.defaultRoom = 'Main';
    this.heartbeatTimeout = null;
    //this.setorderinterval = null;
    this.producerRequested = {};
    this.addedCurrentProducers = false;
    this.reconnecting = false;
    this.handraised = false;
    this.cloudRecorder = false;
    // this.confirmModalNoCallback = null;
    // this.confirmModalYesCallback = null;
    // this.confirmModalCaption = 'Confirm';
    // this.confirmModalBody = 'Are you sure?';
    // this.confirmModalYesButtonStyle = 'btn-primary';
    // this.confirmModalNoButtonStyle = 'btn-secondary';
    //this.recording = false;
    //this.chatopen = false;
    this.jwt = '';
    // this.participantVideoComponentsMap = new Map<string, ParticipantVideoComponent>();
    // this.participantAudioComponentsMap = new Map<string, ParticipantAudioComponent>();
    this.chatmessages = [];

    this.audioPublishedSubject = new Subject();
    this.audioStoppedSubject = new Subject();
    this.videoPublishedSubject = new Subject();
    this.videoStoppedSubject = new Subject();
    this.participantRemovedSubject = new Subject();
    this.participantBeingRemovedSubject = new Subject();
    this.participantProducerRemovedSubject = new Subject();
    this.chatAddedSubject = new Subject();
    this.screenSharePublishedSubject = new Subject();
    this.participantAddedSubject = new Subject();
    this.screenShareVideoProducerAddedSubject = new Subject();
    this.screenShareAudioProducerAddedSubject = new Subject();
    this.meetingEndedSubject = new Subject();
    this.producerTypeUnavailableSubject = new Subject();
    this.takeAttendanceSubject = new Subject();
    this.reconnectingSubject = new Subject();
    this.reconnectedSubject = new Subject();
    //this.toastrWarning = new Subject();
    //this.toastrSuccess = new Subject();
    //this.toastrError = new Subject();
    this.participantHandRaisedSubject = new Subject();
    this.connectedSubject = new Subject();
    this.handDroppedSubject = new Subject();
    this.videoConsumerRemovedSubject = new Subject();
    this.audioConsumerRemovedSubject = new Subject();
    this.screenShareVideoConsumerRemovedSubject = new Subject();
    this.screenShareAudioConsumerRemovedSubject = new Subject();
    this.remoteVideoProducerAddedSubject = new Subject();
    this.remoteAudioProducerAddedSubject = new Subject();
    this.notifySubject = new Subject();
    this.enteredRoomSubject = new Subject();
    this.customMessageSubject = new Subject();
    this.disconnectedSubject = new Subject();
    this.peerEnteredRoomSubject = new Subject();
    this.allParticipantsRemovedSubject = new Subject();
    this.leaveMeetingSubject = new Subject();
    this.unstableConnectionSubject = new Subject();
    this.connectionRestoredSubject = new Subject();
    this.initialised = false;
    this.createProducerTransportPromise = null;
    this.createConsumerTransportPromise = null;
    this.stopConnecting = false;
    this.audioProducer  = null;
    this.videoProducer = null;
    this.screenShareVideoProducer = null;
    this.screenShareAudioProducer = null;
    this.remoteCommandsPullStream$ = null;
    this.localCommandsPullStream$ = null;

    //await Promise.allSettled([this.disconnect(), this.waitForHeartbeatToStop()]);//this has to be called after all the subjects have been reset

    await this.disconnect();

    this.leaving = false;

    await this.generateClientInstanceId();
  }

  async init(){
    
    //this.leaving = false;
    if(!this.cloudRecorder){
      this.createAudioTrack(true);
    }

    this.initSNo++;
    //debugger;
    this.meetinginfo = await this.meetingservice.getMeeting(this.meetingid).toPromise();

    if(!this.meetinginfo){
      throw new Error(`meetinginfo not set for meeting '${this.meetingid}'`);
    }

    this.localuserinfo = await this.meetingservice.decodeJWT(this.meetingid, this.jwt).toPromise();

    this.meParticipant.host = this.localuserinfo.host;

    if(!this.localuserinfo.host && this.meetinginfo.startParticipantsMuted){
      this.settings.enableMicrophone = false;
      //this.toastrWarning.next('Your microphone has been disabled by the host');
      this.notifySubject.next({messageid: NotificationMessages.microphonedisabledbyhost});
    }

    this.settings.host = this.localuserinfo.host;

    this.meParticipant.name = this.localuserinfo.name;// + ' (Me)';
    this.meParticipant.username = this.localuserinfo.username;
    this.meParticipant.photourl = this.localuserinfo.photourl;

    this.initialised = true;

    this.ensureNotInadvertantlyPublishingInterval = setInterval(async () => {
      if(!this.audioAvailable && this.audioProducer && !this.audioProducer.closed && !this.audioProducer.paused){
        //if audio isn't marked as available (i.e. the user doesn't expect to be publishing)
        console.warn('this.audioAvailable is false, but we appear to be publishing, so we will stop audio')
        this.stopAudio();
      }
      if(!this.videoAvailable && this.videoProducer && !this.videoProducer.closed && !this.videoProducer.paused){
        console.warn('this.videoAvailable is false, but we appear to be publishing, so we will stop video')
        this.stopVideo()
      }
    }, 500);

    await this.setupRXDB();
    //await this.connectToMeeting();
  }

  async setupRXDB(){
    const dbId = crypto.randomUUID();

    this.remoteCommandsPullStream$ = new Subject<RxReplicationPullStreamItem<any, any>>();
    this.localCommandsPullStream$ = new Subject<RxReplicationPullStreamItem<any, any>>();

    this.commandSubscribers = {};
    if(this.rxdbDb && !this.rxdbDb.destroyed){
      //debugger;
      await this.rxdbDb.destroy();
    }
    this.rxdbDb = await createRxDatabase({
      name: `meeting_${dbId}_db`,
      storage: getRxStorageDexie(),
      cleanupPolicy: {
        minimumDeletedTime: 1000 * 60 * 5 //5 minutes
      }
      //storage: getRxStorageDexie()
    });

    const commandSchema = {
      version: 0,
      primaryKey: 'id',
      type: 'object',
      properties: {
        id: {
            type: 'string',
            maxLength: 100 // <- the primary key must have set maxLength
        },
        command: {
            type: 'string'
        },
        args: {
            type: 'string',
        },
        allHosts: {
          type: 'boolean'
        },
        room: {
          type: 'string'
        },
        //only used when the server is sending messages to the client
        socketid: {
          type: 'string'
        },
        done: {
          type: 'boolean'
        },
        successful: {
          type: 'boolean'
        },
        // timedOut: {
        //   type: 'boolean'
        // },
        // timeout: {
        //   type: 'number',
        //   minimum: 0,
        //   maximum: Number.MAX_SAFE_INTEGER,
        //   multipleOf: 1
        // },
        result: {
          type: 'string'
        },
        timestamp: {
            type: 'string',
            format: 'date-time'
        },
        updatetimestamp: {
          type: 'number',
          minimum: 0,
          maximum: Number.MAX_SAFE_INTEGER,
          multipleOf: 1
        }
      },
      required: ['id', 'command', 'timestamp'],
      indexes: [
        ['updatetimestamp'],
        // ['transmitted']
      ]
    };

    const collections = await this.rxdbDb.addCollections({
      locally_initiated_commands: {
        schema: commandSchema
      },
      remotely_initiated_commands: {
        schema: commandSchema
      }
    });

    this.remotelyInitiatedCommandsCollection = collections.remotely_initiated_commands;
    this.locallyInitiatedCommandsCollection = collections.locally_initiated_commands;

    this.remotelyInitiatedCommandsCollection.insert$.subscribe(async (changeEvent: any) => {
      //debugger;
      //console.dir('remote command received: ', changeEvent);

      const commandData = changeEvent.documentData;

      //once we have received the command, we don't need it in the rxdb db any longer, as we don't need to provide feedback to the server. 
      //so we delete it, and the cleanup policy should purge it
      //debugger;
      const commandDocument = await this.remotelyInitiatedCommandsCollection.findOne(changeEvent.documentId).exec();
      if(commandDocument){
        await commandDocument.remove();
      }
      
      if(commandDocument.socketid != this.clientInstanceId){
        return;
      }

      if(this.commandSubscribers[commandData.command]){
        const fn = this.commandSubscribers[commandData.command];
        try{
          await fn(commandData.args);
        }
        catch(error){
          console.error(error);

          //TODO: what do we do if we are unable to honour a command from the server???
          //I think this calls for a hard reconnect
          //we can only do a hard reconnect after we have an initial successful connection 
          //because the server could issue a rejection, which would cause a failed promise which would then throw an error

          if(this.criticalServerCommands.has(commandData.command)){
            await this.handleDisconnected('');
          }
        }
      }
      //call the subscriber
    });

    this.remoteCommandsReplicationState = await replicateRxCollection({
      collection: this.remotelyInitiatedCommandsCollection,
      replicationIdentifier: `remote_commands`,
      //url: `${environment.mediasoupScheme}://${domain.domain}:${domain.port}/remotely_initiated_commands/${commandSchema.version}`,
      live: true,
      retryTime: 1 * 1000,
      waitForLeadership: false,
      autoStart: true,
      push: undefined,//we don't do any modifications on received remote commands, so no need for a push handler
      pull: {
        handler: async (lastCheckpoint: any, batchSize) => {
          console.log('pulling remote commands. parameters: ', {lastCheckpoint, batchSize});
          const minTimestamp = lastCheckpoint ? lastCheckpoint.updatedAt : 0;
          const lastId = lastCheckpoint ? lastCheckpoint.id : 0;

          if(!this.rxdbReplicationSocket || this.rxdbReplicationSocket.closed || !this.rxdbReplicationSocket.connected){
            return {
              documents: [],
              checkpoint: lastCheckpoint
            }
          }

          try{
            const documentsFromRemote: any = await this.util.runFunctionWithTimeout(() => this.sendRxDbCommandOverSocket(this.rxdbReplicationSocket, 'rxdb_server_command_pull', {lastId, minTimestamp, batchSize}), this.connectTimeoutMs);
            console.log('commands retreived: ', documentsFromRemote);
            return {
              documents: documentsFromRemote,
              checkpoint: documentsFromRemote.length === 0 ? lastCheckpoint : {
                  id: documentsFromRemote[documentsFromRemote.length - 1].id,
                  updatedAt: documentsFromRemote[documentsFromRemote.length - 1].updatetimestamp
              }
            };
          }
          catch(error){
            console.error(`error pulling server commands: `, error);
            return {
              documents: [],
              checkpoint: lastCheckpoint
            }
          }
          //debugger;

          
        },
        batchSize: 10,
        modifier: d => d,
        stream$: this.remoteCommandsPullStream$.asObservable()
      },
      // headers: {
      //   Authorization: `Bearer ${this.jwt}`,
      //   clientInstanceId: this.clientInstanceId
      // },
    });

    this.localCommandsReplicationState = await replicateRxCollection({
      collection: this.locallyInitiatedCommandsCollection,
      replicationIdentifier: `local_commands`,
      live: true,
      retryTime: 1 * 1000,
      waitForLeadership: false,
      autoStart: true,
      push: {
        handler: async (docs) => {
          //debugger;
          // const untransmittedDocs = await locallyInitiatedCommandsCollection.find({
          //   selector: {transmitted: { $eq: 0 }}
          // }).exec();
          // debugger;
          //console.log('pushing local command docs: ', docs);
          //debugger;
          //return [];
          //let maxTimeout = this.maximumHeartbeatResponseTimeMs;
          
          docs = docs.filter((doc) => {
            //we don't need to replicate the deleted state
            //the server already cleans up done commands
            return !doc.newDocumentState._deleted;
          });
          console.log('pushing local command filtered docs: ', docs);
          if(docs.length > 0){
            let conflictsArray: any = [];

            //console.log('pushing commands: ', docs);
            for(let i = 0; i < docs.length; i++){
              const doc = docs[i];

              // const deleteUntransmittedCommandFunction = async () => {
              //   const commandDocument = await this.locallyInitiatedCommandsCollection.findOne(doc.newDocumentState.id).exec();
              //   if(commandDocument){
              //     commandDocument.remove();
              //   }
              // }

              if(!this.rxdbReplicationSocket || (!this.rxdbReplicationSocket.connected || !this.rxdbReplicationSocket.connected)){
                console.error('no connected replication socket found: ', {command: doc.newDocumentState});

                return null;
                //if we don't have an active connection, we'll return null instead of an array so that the replication tries again
                // if(doc.newDocumentState.command != 'no-op'){
                //   //we don't want to retransmit old no-op commands since handleDisconnected() already retries these
                //   //rxdb will retry in retryTime based on settings above
                //   return null;
                // }
                // else{
                //   deleteUntransmittedCommandFunction();
                // }
              }

              

              try{
                //debugger;
                const _docsConflictArray: any[] = await this.util.runFunctionWithTimeout(() => this.sendRxDbCommandOverSocket(this.rxdbReplicationSocket, 'rxdb_client_command_push', [doc]), this.connectTimeoutMs);
                conflictsArray.push(..._docsConflictArray);
              }
              catch(error){
                console.error('error sending rxdb command over socket: ', {command: doc.newDocumentState, error});
                if(!this.rxdbReplicationSocket || (!this.rxdbReplicationSocket.connected || this.rxdbReplicationSocket.disconnected)){
                  console.error('no connected replication socket found: ', {command: doc.newDocumentState, error});
                  //if we don't have an active connection, we'll return null instead of an array so that the replication tries again
                  return null;
                }
                else if(error == 'timeout'){
                  //this.handleDisconnected(doc.newDocumentState.id);
                  //i commented out the above line because if we have timed out here, it would have timed out in the actual method used to push the command to rxdb (sendMediasoupControlRequest). 
                  // so we don't need to handleDisconnected here

                  //i don't think we need to push any conflicts because we'd be in a timeout condition

                  // const commandDocument = await this.locallyInitiatedCommandsCollection.findOne(doc.newDocumentState.id).exec();
                  // if(commandDocument){
                  //   commandDocument.patch({
                  //     timedOut: true
                  //   });
                  // }
                  //the timeout error is handled in sendMediasoupControlRequest()
                  //TODO: that is part of the problem. the timeout error should happen here or we send in parallel...that's the right move...no wrong move
                  //because when one push iteration is running, others have to wait...
                  //which means with the current method, they could time out without even having the opportunity to try to be transmitted
                  //if we handle the actual timeouts here, then we'll know that the timeout is real

                  //if this command times out, then we'll assume it has failed and is no longer needed
                  //await deleteUntransmittedCommandFunction();
                  //the command will be deleted in the .$.subscribe
                }
              }
            }
            
            if(conflictsArray.length > 0){
              console.error('conflicts: ', conflictsArray);
            }
            
            return conflictsArray;
            
          }
          else{
            //console.log('no commands to push');
            return [];
          }
        },
        batchSize: 10,
        modifier: d => d
      },
      pull: {
        handler: async (lastCheckpoint: any, batchSize) => {
          console.log('pulling local commands. parameters: ', {lastCheckpoint, batchSize});
          const minTimestamp = lastCheckpoint ? lastCheckpoint.updatedAt : 0;
          const lastId = lastCheckpoint ? lastCheckpoint.id : 0;

          if(!this.rxdbReplicationSocket || this.rxdbReplicationSocket.closed || !this.rxdbReplicationSocket.connected){
            return {
              documents: [],
              checkpoint: lastCheckpoint
            }
          }

          try{
            const documentsFromRemote: any = await this.util.runFunctionWithTimeout(() => this.sendRxDbCommandOverSocket(this.rxdbReplicationSocket, 'rxdb_client_command_pull', {lastId, minTimestamp, batchSize}), this.connectTimeoutMs);

            //debugger;

            return {
              documents: documentsFromRemote,
              checkpoint: documentsFromRemote.length === 0 ? lastCheckpoint : {
                  id: documentsFromRemote[documentsFromRemote.length - 1].id,
                  updatedAt: documentsFromRemote[documentsFromRemote.length - 1].updatetimestamp
              }
            };
          }
          catch(error){
            console.error(`error while pulling client commands: `, error);
            return {
              documents: [],
              checkpoint: lastCheckpoint
            }
          }
        },
        batchSize: 10,
        modifier: d => d,
        stream$: this.localCommandsPullStream$.asObservable()
      }
      // headers: {
      //   Authorization: `Bearer ${this.jwt}`,
      //   clientInstanceId: this.clientInstanceId
      // },
    });

    this.remoteCommandsReplicationState.received$.subscribe(doc => {
      //whenever replication gets stuck, uncomment the below console logs and it may give you a clue
      //debugger;
      //console.log('remote command seen in replication state', doc);
    });

    this.localCommandsReplicationState.received$.subscribe(doc => {
      //debugger;
      //console.log('local command seen in replication state', doc);
    });

    this.localCommandsReplicationState.active$.subscribe((active: boolean) => {
      //console.log('localCommandsReplicationState active: ', active);
      //debugger;
      //console.log('local command seen in replication state', doc);
    });

    this.remoteCommandsReplicationState.error$.subscribe(async (error: any) => {
      console.error(error);
      //canceling replication to be sure that nothing else comes in
      //await this.stopRXDBReplication();

      //trigger a hard reconnect
      //this.handleDisconnected(true, true);
    });

    this.localCommandsReplicationState.error$.subscribe(async (error: any) => {
      console.error(error);
      //canceling replication to be sure that nothing else comes in
      //await this.stopRXDBReplication();

      //trigger a hard reconnect
      //this.handleDisconnected(true, true);
    });
  }

  async stopAudio(){
    //we want to be able to stop video while publishVideo is in flight, to allow the user stop the video instantly. 
    //publishVideo should check if video is still enabled by the time it finishes. 
    //if it isn't, it should close the producer it just created
    try{
      await Locker.lock(this.audioPublishingLocker);
      const producer = this.audioProducer
      if(producer){
        this.pauseProducer(producer, false);
      }
      
      //putting this here to ensure that the value is set so that controls work properly even if there was no producer or no track set
      this.audioAvailable = false;
      this.meParticipant.audioAvailable = false;

      this.audioStoppedSubject.next(undefined);
    }
    finally{
      Locker.unlock(this.audioPublishingLocker);
    }
  }

  async createAudioTrack(force: boolean = false){
    try{
      if(this.localAudioTrack){
        this.localAudioTrack.stop();
      }
    }
    catch(error){
      console.log(error);    
    }
    this.localAudioTrack = (await navigator.mediaDevices.getUserMedia({audio: this.settings.getAudioConstraints(force)})).getAudioTracks()[0];
  }

  async publishAudio(auto: boolean = false){

    const _createAndPublishAudioInAdvance = async () => {
      this.selectedMicrophone = this.settings.selectedMicrophone;
      
      await this.createAudioTrack(true);
      //ensure the producer is created paused
      //the server also has to know that even though it is published, the producer's mic is off to we know not to enable that icon
      //this producer has been started paused
      await this.publishToMediasoupRoom(this.localAudioTrack!, false, undefined, false, true, true);
    }

    if(this.meetinginfo!.mode == this.util.meetingmode && !this.audioProducer){
      //the first time this is called, in meeting mode and there's no producer, create a producer and publish it with a paused audio track
      //we're doing this so that enabling your mic becomes lightning fast
      await _createAndPublishAudioInAdvance();
    }

    if(!(this.meetinginfo!.mode == this.util.meetingmode || this.localuserinfo.host || this.meParticipant.unlocked)){
      if(!auto){
        this.notifySubject.next({messageid: NotificationMessages.notyetenabledtospeak});
      }
      else{
        this.notifySubject.next({messageid: NotificationMessages.microphonedisabledbyhost});
      }
      return false;
    }

    if(this.settings.enableMicrophone){

      //if we have reached the max number of audio participants, we allow only the host to produce audio
      if(!this.localuserinfo.host){
        let audioConsumerCount = 0;
        this.participantsMap.forEach((participant, socketId) => {
          if(participant.audioAvailable){
            audioConsumerCount++;
          }
        });

        if(audioConsumerCount >= this.maxSpeakingParticipants){
          this.notifySubject.next({messageid: NotificationMessages.maxSpeakingParticipantsReached});
          return false;
        }
      }
      
      try{
        await Locker.lock(this.audioPublishingLocker);

        let newpublish = false;
        if((this.selectedMicrophone && this.selectedMicrophone != this.settings.selectedMicrophone && this.localAudioTrack)){
          const producer = this.audioProducer;
          if(producer){
            this.closeProducer(producer);
          }
          newpublish = true;
        }

        //let currentAudioProducer;
        if(!this.localAudioTrack){
          newpublish = true;
        }
        else{
          if(!this.audioProducer || this.audioProducer.closed){
            newpublish = true;
          }
        }

        if(newpublish){
          await _createAndPublishAudioInAdvance();
        }

        
        // this.audioTrack.addEventListener('ended', () => {
        //   this.audioAvailable = false;
        //   const producer = this.findProducerByTrack(this.audioTrack);
        //   if(producer){
        //     this.closeProducer(producer);
        //   }
        // });
        
        this.audioAvailable = true;
        this.meParticipant.audioAvailable = true;

        await this.resumeProducer(this.audioProducer);

        this.audioPublishedSubject.next(this.localAudioTrack);

        return true;
      }
      catch(error){
        console.error(error);
        this.stopAudio();//we can't await here because if we do, it will never return. if we fire it here, it will happen after the locker is unlocked
        this.notifySubject.next({messageid: NotificationMessages.errorGettingAudioStream});
        return false;
      }
      finally{
        Locker.unlock(this.audioPublishingLocker);
      }
    }
    return false;

  }

  async stopVideo(){
    try{
      //we want to be able to stop video while publishVideo is in flight, to allow the user stop the video instantly. 
      //publishVideo should check if video is still enabled by the time it finishes. 
      //if it isn't, it should close the producer it just created
      await Locker.lock(this.videoPublishLocker);

      if(this.localVideoTrack){
        if(this.videoProducer){
          this.closeProducer(this.videoProducer);
        }
        else {
          this.localVideoTrack.stop();
        }        
      }
      //putting this here to ensure that the value is set so that controls work properly even if there was no producer or no track set
      this.videoAvailable = false;
      this.meParticipant.videoAvailable = false;

      this.videoStoppedSubject.next(undefined);
    }
    finally{
      Locker.unlock(this.videoPublishLocker);
    }
    // this.videoTrack.stop();
    // this.videoAvailable = false;
  }

  async createVideoTrack(){
    try{
      if(this.localVideoTrack){
        this.localVideoTrack.stop();
      }
      this.stopVideoStream();
      if(this.settings.selectedBackgroundImage){       
        this.getCanvasStream();
        this.localVideoTrack = this.inMeetingVideoStream!.getVideoTracks()[0];
      }
      else{
        this.inMeetingVideoStream = await navigator.mediaDevices.getUserMedia({video: this.settings.getVideoConstraints()});
        this.localVideoTrack = this.inMeetingVideoStream.getVideoTracks()[0];
      }
    }catch(e){
      console.log('Error creating video track:', e);
      
    }  
  }  

  async publishVideo(auto: boolean){
    if(!(this.meetinginfo!.mode == this.util.meetingmode || this.localuserinfo.host || this.meParticipant.unlocked)){
      if(!auto){
        this.notifySubject.next({messageid: NotificationMessages.notyetenabledtospeak});
      }
      else{
        this.notifySubject.next({messageid: NotificationMessages.webcamdisabledbyhost});
      }
      return false;
    }

    

    if(this.settings.enableCamera){
      
      try{
        //stopVideo must be called before acquiring video track because of Locker instance sharing.
        try {
          await this.stopVideo();
        }
        catch {
          console.warn('Error while stopping video stream.');
        }
        await Locker.lock(this.videoPublishLocker);

        await this.createVideoTrack();
        //this.localVideoTrack = (await navigator.mediaDevices.getUserMedia({video: this.settings.getVideoConstraints()})).getVideoTracks()[0];

        this.localVideoTrack!.addEventListener('ended', () => {
          console.log('video track ended');
        });

        this.selectedCamera = this.settings.selectedCamera;
        // this.videoTrack.addEventListener('ended', () => {
        //   this.videoAvailable = false;
        //   const producer = this.findProducerByTrack(this.videoTrack);
        //   if(producer){
        //     this.closeProducer(producer);
        //   }
        // });
         this.videoAvailable = true;
         this.meParticipant.videoAvailable = true;
        await this.publishToMediasoupRoom(this.localVideoTrack!);
       
        this.videoPublishedSubject.next(this.localVideoTrack);

        return true;
      }
      catch(error){
        console.error(error);
        this.stopVideo();//we can't await here because if we do, it will never return. if we fire it here, it will happen after the locker is unlocked
        this.notifySubject.next({messageid: NotificationMessages.errorGettingVideoStream});
        return false;
      }
      finally{
        Locker.unlock(this.videoPublishLocker);
      }
    }
    else{
      return false;
    }
  }

  async stopScreenShare(){
    try{
      await Locker.lock(this.screenPublishingLocker);

      if(this.screenShareVideoProducer){
        this.closeProducer(this.screenShareVideoProducer);
      }

      if(this.screenShareAudioProducer){
        this.closeProducer(this.screenShareAudioProducer);
      }
    }
    finally{
      Locker.unlock(this.screenPublishingLocker);
    }
    
  }

  async publishScreenShare(republish = false){
    if(!(this.meetinginfo!.mode == this.util.meetingmode || this.localuserinfo.host || this.meParticipant.unlocked)){
      return false;
    }

    try{
      await Locker.lock(this.screenPublishingLocker);

      if(!republish) {//if we aren't republishing, and we already have screen share tracks, stop them
        if(this.screenShareVideoTrack){
          try{
            this.screenShareVideoTrack.stop();
          }catch(error){
            console.log(error);
          }
        }

        if(this.screenShareAudioTrack){
          try{
            this.screenShareAudioTrack.stop();
          }catch(error){
            console.log(error);
          }
        }
      }

      if(!republish || !this.screenShareVideoTrack){
        //if this is not a republish, or we can't find an existing screen share video track, create audio and video screen share tracks
        //a republish happens (typically) when the user disconnects while they were sharing and reconnects
        const mediadevices: any = navigator.mediaDevices;

        const screenShareStream = await mediadevices.getDisplayMedia({ video: this.settings.getScreenShareVideoConstraints(), audio: true}); 
        this.screenShareVideoTrack = screenShareStream.getVideoTracks()[0];
        this.screenShareAudioTrack = screenShareStream.getAudioTracks()[0];
      }

      const producer = await this.publishToMediasoupRoom(this.screenShareVideoTrack!, true);

      if(producer){
        this.screenShareVideoProducerId = producer.id;
        
        const producers: any[] = [];
        producers.push(producer);

        if(this.screenShareAudioTrack){
          const screenShareAudioProducer = await this.publishToMediasoupRoom(this.screenShareAudioTrack, true);
          if(screenShareAudioProducer){
            this.screenShareAudioProducerId = screenShareAudioProducer.id;

            producers.push(screenShareAudioProducer);
          }
          //this.localScreenShareAudioElement.play();
        }

        this.screenShareAvailable = true;

        this.screenSharePublishedSubject.next(producers);

        

        return true;
      }
      else{
        this.screenShareVideoTrack?.stop();
        this.screenShareAudioTrack?.stop();
        
        return false;
      }
    }
    catch(error){
      console.error(error);

      this.notifySubject.next({messageid: NotificationMessages.errorSharingScreen});

      this.stopScreenShare();//we can't await here. see publishAudio and publishVideo for explanation
      
      //doing the below in case the producers had not been created but the tracks were
      this.screenShareVideoTrack?.stop();
      this.screenShareAudioTrack?.stop();

      return false;
    }
    finally{
      Locker.unlock(this.screenPublishingLocker);
    }
  }

  // findProducerByTrack(track: MediaStreamTrack){
  //   for(let i = 0; i < this.localMediasoupProducers.length; i++){
  //     if(this.localMediasoupProducers[i].track.id == track.id){
  //       return this.localMediasoupProducers[i];
  //     }
  //   }
  //   return null;
  // }

  closeProducerTransport(){
    //this.mediasoupProducerTransport.off('connectionstatechange');
    this.mediasoupProducerTransport.close();
    if(this.isMediasoupControlSocketConnected()){
      //no need to await. even without this, the server should know it has been closed via the observer
      this.sendMediasoupControlRequest('closeProducerTransport', { socketId: this.clientInstanceId });
    }
    
  }

  closeConsumerTransport(){
    //this.mediasoupConsumerTransport.off('connectionstatechange');
    this.mediasoupConsumerTransport.close();
    if(this.isMediasoupControlSocketConnected()){
      //no need to await. even without this, the server should know it has been closed via the observer
      this.sendMediasoupControlRequest('closeConsumerTransport', { socketId: this.clientInstanceId });
    }
  }

  async disconnect() {
    console.warn('disconnect called');
    //await this.sendMediasoupControlRequest('_disconnect', {}, this.connectTimeoutMs);
    //we're using this so we don't have to await the disconnection
    if(this.domain){
      await this.meetingservice.selfDisconnectAndDeassign(this.domain, this.jwt, this.clientInstanceId, false);
    }
    this.ready = false;
   
    if (this.mediasoupProducerTransport && !this.mediasoupProducerTransport.closed) {
      try{
        this.closeProducerTransport();
      }
      catch(error){
        console.warn(error);
      }
    }

    if (this.mediasoupConsumerTransport && !this.mediasoupConsumerTransport.closed) {
      try{
        this.closeConsumerTransport();
      }
      catch(error){
        console.warn(error);
      }
    }

    this.mediasoupProducerTransport = null;
    this.mediasoupConsumerTransport = null;

    //this.participantsMap = new Map<string, Participant>();
    //this.participantsArray = [];
    //this.localMediasoupProducers = [];

    //if(this.socket && this.socket.connected){
      //console.log('socket.close called');
      //this.socket.close();
      await this.closeSocket();
    //}

    //if(this.connected){
    this.disconnectedSubject.next();
    //}
    

    //this.mediasoupConsumers = {};
  }

  async closeSocket(){
    //this.socket.off('disconnect');
    //this.socket.close();

    //await this.stopRXDBReplication();

    //delete all documents
    let removePromises: Promise<any>[] = [];

    if(this.locallyInitiatedCommandsCollection){
      const commands = await this.locallyInitiatedCommandsCollection.find().exec();
      
      commands.forEach((command: any) => {
        removePromises.push(command.remove());
      });
    }
    
    if(this.remotelyInitiatedCommandsCollection){
      const commands = await this.remotelyInitiatedCommandsCollection.find().exec();
      commands.forEach((command: any) => {
        removePromises.push(command.remove());
      });
    }

    await Promise.allSettled(removePromises);

    await Promise.allSettled([this.locallyInitiatedCommandsCollection?.cleanup(0), this.remotelyInitiatedCommandsCollection?.cleanup(0)]);

    // if(this.rxdbDb){
    //   //debugger;
    //   //const temp = this.rxdbDb;
    //   if(!this.rxdbDb.destroyed){
    //     await this.rxdbDb.destroy();
    //   }
    //   this.rxdbDb = null;
    //   this.commandSubscribers = {};
    // }

    this.connected = false;
  }

  private async stopRXDBReplication() {
    const promises: any[] = [];

    if (this.remoteCommandsReplicationState) {
      promises.push(this.remoteCommandsReplicationState.cancel());
    }
    if (this.localCommandsReplicationState) {
      promises.push(this.localCommandsReplicationState.cancel());
    }

    if(this.rxdbReplicationSocket){
      try{
        this.rxdbReplicationSocket.close();
      }
      catch{}
    }

    try {
      await Promise.allSettled(promises);
    }
    catch (error) {
      console.error(error);
    }
  }

  // get selectedDomain() : MeetingDomain{
  //   if(this.meetinginfo.domains.length > this.domainIndex && this.domainIndex >= 0){
  //     return this.meetinginfo.domains[this.domainIndex];
  //   }
  //   else{timeou
  //     return null;
  //   }
  // }
  sendRxDbCommandOverSocket(socket: any, type: string, data: any) {
    // console.log('sending command', {
    //   type,
    //   data
    // });
    //console.log('sendRxDBCommandOverSocket: ', arguments);
    return new Promise<any>((resolve, reject) => {
      socket.emit(type, data, (err, response) => {
        if (!err) {
          // Success response, so pass the mediasoup response to the local Room.
          resolve(response);
        } else {
          console.error('error received as emit response: ', {type, data, err});
          reject(err);
        }
      });
    });
  }

  sendMediasoupControlRequest(type: string, data: any) {
    //console.log('sendMediasoupControlRequest: ', arguments);
    if(!this.rxdbDb || this.rxdbDb.destroyed || !this.rxdbDb.locally_initiated_commands){
      console.error(`Can\'t send command `, {type, data} , ` to server because database is not initialised`);
      throw new Error(`RXDB not initialized`);
      //return null;
    }
    return new Promise(async (resolve, reject) => {
      let commandHasBeenResolvedOrRejected  = false;
     
      const date = new Date();

      const commandId = this.clientInstanceId + "_" + (++this.outgoingRxDBMessageId).toString();

      const commandDocument = await this.rxdbDb.locally_initiated_commands.insert({
        id: commandId,
        command: type,
        args: JSON.stringify(data),
        socketid: this.clientInstanceId,
        done: false,
        successful: false,
        result: null,
        timestamp: date.toISOString(),
        //transmitted: 0
      });

      console.log(`sending command '${type}': `, commandDocument._data);

      const start = new Date().getTime();

      const logTimeTakenFunction = (commandData) => {
        const end = new Date().getTime();

        const responseTime = end - start;
        //if(!this.production){
        console.log(`command '${type}' response time (ms): ${responseTime}. Command: `, commandData);
        //}
      }

      
      this.util.sleep(this.connectTimeoutMs).then(() => {
        if(!commandHasBeenResolvedOrRejected){
          commandHasBeenResolvedOrRejected = true;
          // if(commandDocument.socketid != this.clientInstanceId){
          //   if(!commandDocument.deleted){
          //     commandDocument.remove();
          //   }
          //   return;
          // }
          logTimeTakenFunction(commandDocument._data);
          
          //if it hasn't been resolved by the timeout time, then reject
          console.error(`Timeout of ${this.connectTimeoutMs} ms elapsed for command '${type}'`);
          reject('timeout');
          //this.handleCriticalClientCommandFailure(commandDocument.data);
          //if a command has timed out (even with the generous timeout, we should just do a full reconnect)
          //we can't just retry because the server may have executed the command
          //so we won't know the actual state
          //so we'll condemn everything and reconnect
          //the only command that can fail freely is no-op. and things like closeConsumerTransport which would naturally fail when clening up the client if the connection has been lost
          //a timeout probably means one of the following:
            //rxdb has tried multiple times to send it to the server, but each time, there was no connection
            //we've tried to send but the socket got closed while the command was in transmission (in this case, we'll never know the final state)
            //there's something very wrong on the server and the server never returned success or failure on the command
          //in each of these cases, it is not particularly safe to recover because the state between client and server may be out of sync
          //so we'll do a full reconnect
          
          if(!this.commandsThatCanTimeout.has(type)){
            this.handleDisconnected(commandId);
          }
          else{
            console.warn(`not timing out because command '${type}' is allowed to timeout`);
          }
        }
      });
      

      commandDocument.$.subscribe(async currentDocument => {
        if(currentDocument.socketid != this.clientInstanceId){
          if(!currentDocument.deleted){
            await currentDocument.remove();
          }
          return;
        }
        //debugger;
        //console.log('currentDocument: ', currentDocument);

        let resultObject = null;

        if(currentDocument.result !== null && currentDocument.result !== undefined){
          try{
            resultObject = JSON.parse(currentDocument.result);
          }
          catch(error: any){
            console.log('Unable to parse result of command', {command: type, args: data, id: currentDocument.id})
            throw new Error(`Unable to parse result '${currentDocument.result}' for command ${currentDocument.id}`);
          }
        }

        if(currentDocument && currentDocument.done && !currentDocument.deleted){
          //if it had timed out, it would have been deleted already
          logTimeTakenFunction(currentDocument._data);

          //console.log('locally initiated command updated: ', currentDocument);
          if(!commandHasBeenResolvedOrRejected){
            commandHasBeenResolvedOrRejected = true;
              //debugger;
            if(currentDocument.successful){
              resolve(resultObject);
            }
            else{
              reject(resultObject);
            }

            //the command is complete, so we can remove it
            //removing it will remove it from the client and the rxdb server
            if(!currentDocument.deleted){
              await currentDocument.remove();
            }
          }
        }
      });
    });
  
  }

  sendCustomMessage(messageId: string, data: any){
    return this.sendMediasoupControlRequest('customMessage', {messageId: messageId, message: data});
  }

  sendCustomPrivateMessage(messageId: string, remoteId: string, data: any){
    return this.sendMediasoupControlRequest('customPrivateMessage', {messageId: messageId, remoteId: remoteId, message: data});
  }

  addParticipant(socketid, username, name, host, photourl, data): Participant {
    const participant: Participant = new Participant();
    participant.username = username;
    participant.name = name;
    participant.host = host;
    participant.photourl = photourl;
    participant.socketid = socketid;
    

    if(data){
      participant.data = JSON.parse(data);
    }

    if(!this.participantsMap.has(socketid))
    {
      //this.participantsArray.push(participant);
      this.participantsMap.set(socketid, participant);
      this.participantsUsernameMap.set(participant.username, participant);
      
      this.participantAddedSubject.next(participant);
    }

    return participant;
  }

  async addParticipantProducer(socketId: string, producerId: string, kind: string, screenShare: boolean, privateProducer: boolean) {
    //if(this.test){ return; }//tbhis was used to test proctoring scenario where users are broadcasting but not consuming

    let participant = this.findParticipant(socketId);
    if (!participant) {
      
      // participant = new Participant();
      // participant.socketid = socketId;
      // //proctee.muted = true;
      // participant.username = '';
      // participant.name = '';
      
      //this.participants.set(socketId, participant);
      const { username, name, host, photourl, data } = await this.getMediasoupSocketIdUserDetails(socketId);
      
      participant = this.addParticipant(socketId, username, name, host, photourl, data);
      
      // participant.username = username;
      // participant.name = name;
      // participant.host = host;
      // participant.photourl = photourl;
    }
    
    if (kind === 'video') {
      if(screenShare){

        this.screenShareVideoParticipantProducerId = producerId;
        this.screenShareParticipantSocketId = socketId;
        this.screenShareParticipantUsername = participant.username;
        this.screenShareParticipantName = participant.name;
        this.participantScreenShareAvailable = true;
        this.screenShareVideoProducerAddedSubject.next({socketid: socketId, producerid: producerId, kind: kind, private: privateProducer});
      }
      else {
        // if(!participant.videoConsumer || participant.videoConsumer.producerId != producerId)
        // {
          participant.videoProducerId = producerId;

          this.remoteVideoProducerAddedSubject.next({socketid: socketId, producerid: producerId, kind: kind, private: privateProducer});
          // participant.videoConsumer = await this.createConsumer(participant.socketid, participant.videoProducerId, kind);
          // participant.videoAvailable = true;
        //}
        /*const participantVideoComponent = this.getParticipantVideoComponent(socketId);
        if(participantVideoComponent){
          await participantVideoComponent.setStream();
        }*/
      }
    } 
    else {
      if(screenShare){
        //debugger;
        this.screenShareAudioParticipantProducerId = producerId;

        this.screenShareAudioProducerAddedSubject.next({socketid: socketId, producerid: producerId, kind: kind, private: privateProducer});
      }
      else{
      // if(!participant.audioConsumer || participant.audioConsumer.producerId != producerId)
      // {
        participant.audioProducerId = producerId;

        this.remoteAudioProducerAddedSubject.next({socketid: socketId, producerid: producerId, kind: kind, private: privateProducer});
        // participant.audioConsumer = await this.createConsumer(participant.socketid, participant.audioProducerId, kind);
        // participant.audioAvailable = true;
      // }
      }
    }
    
    this.producerIdSocketIdMap.set(producerId, socketId);
    //this.ref.detectChanges();
    
  }

  getVideoConsumer(producerId: string){
    if(this.videoConsumersMap.has(producerId)){
      const consumer = this.videoConsumersMap.get(producerId);
      return consumer;
    }
    else{
      return null;
    }
  }

  getAudioConsumer(producerId: string){
    if(this.audioConsumersMap.has(producerId)){
      const consumer = this.audioConsumersMap.get(producerId);
      return consumer;
    }
    else{
      return null;
    }
  }

  async removeParticipant(socketId){
    const participant = this.participantsMap.get(socketId);
    if(participant){
      //const participantVideoComponent = this.getParticipantVideoComponent(socketId);
      const videoConsumer = this.getVideoConsumer(participant.videoProducerId ?? '');
      if(participant && videoConsumer && !videoConsumer.closed){
        //this.closeConsumer(participant.socketid, videoConsumer, false);
        this.removeParticipantProducer(socketId, videoConsumer.producerId, 'video');
      }

      const audioConsumer = this.getAudioConsumer(participant.audioProducerId ?? '');
      if(participant && audioConsumer && !audioConsumer.closed){
        //this.closeConsumer(participant.socketid, audioConsumer, false);
        this.removeParticipantProducer(socketId, audioConsumer.producerId, 'audio');
      }

      if(this.participantScreenShareAvailable && this.screenShareParticipantSocketId == socketId){
        if(this.screenShareVideoConsumer && !this.screenShareVideoConsumer.closed){
          //this.closeConsumer(participant.socketid, this.screenShareVideoConsumer, false);
          this.removeParticipantProducer(socketId, this.screenShareVideoConsumer.producerId, 'video');
        }
        if(this.screenShareAudioConsumer && !this.screenShareAudioConsumer.closed){
          //this.closeConsumer(participant.socketid, this.screenShareAudioConsumer, false);
          this.removeParticipantProducer(socketId, this.screenShareAudioConsumer.producerId, 'audio');
        }
      }
      //if (!participant.videoConsumer && !participant.audioConsumer) {

      this.participantBeingRemovedSubject.next(socketId);

      this.participantsMap.delete(socketId);

      this.participantRemovedSubject.next(socketId);
    }
      //we can't remove from the participants array because it will affect the indexes stored in participantsMap
      // for(let i = 0; i < this.participantsArray.length; i++){
      //   if(this.participantsArray[i].socketid == socketId){
      //     this.participantsArray.splice(i, 1);
      //     i--;
      //   }
      // }
      // this.setPages();
      // this.goToPage(this.participantsPageNo);
    //}
  }

  async removeParticipantProducer(socketId, producerId, kind) {
    
    if(socketId == this.clientInstanceId){
      const producers = [this.audioProducer, this.videoProducer, this.screenShareVideoProducer, this.screenShareAudioProducer];
      for(let i = 0; i < producers.length; i++){
        try{
          if(producers[i] && producers[i].id == producerId){
            this.closeProducerLocally(producers[i], false);
          }
        }
        catch(error){
          console.error(error);
        }
      }
    }
    else{
      
      if (this.participantsMap.has(socketId)) {
        //const participantindex = this.participantsMap.get(socketId);
        const participant = this.participantsMap.get(socketId)!;//[participantindex];
       if (participant.videoProducerId === producerId/* && participant.videoConsumer*/) {

          if(this.videoConsumersMap.has(participant.videoProducerId ?? '')){

            const consumer = this.videoConsumersMap.get(participant.videoProducerId ?? '');
            this.closeConsumer(socketId, consumer, false);

            this.videoConsumersMap.delete(participant.videoProducerId ?? '');
          }

          
          participant.videoProducerId = null;

          this.videoConsumerRemovedSubject.next(socketId);

          
          // participant.videoConsumer = null;
          // participant.videoAvailable = false;
        }
        else if (participant.audioProducerId === producerId/* && participant.audioConsumer*/) {

          if(this.audioConsumersMap.has(participant.audioProducerId ?? '')){
            const consumer = this.audioConsumersMap.get(participant.audioProducerId ?? '');
            this.closeConsumer(socketId, consumer, false);

            this.audioConsumersMap.delete(participant.audioProducerId ?? '');
          }
          // this.closeConsumer(socketId, participant.audioConsumer, false);
          participant.audioProducerId = null;
          
          this.audioConsumerRemovedSubject.next(socketId);
          // participant.audioConsumer = null;
          // participant.audioAvailable = false;
        }
        else if(this.screenShareVideoParticipantProducerId == producerId && this.screenShareVideoConsumer){
          this.closeConsumer(socketId, this.screenShareVideoConsumer, false);
          
          this.screenShareVideoParticipantProducerId = null;
          this.screenShareVideoConsumer = null
          this.screenShareParticipantSocketId = null;
          this.screenShareParticipantUsername = null;
          this.screenShareParticipantName = null
          this.participantScreenShareAvailable = false;

          if(this.screenShareAudioConsumer){
            this.closeConsumer(socketId, this.screenShareAudioConsumer, false);
            this.screenShareAudioParticipantProducerId = null;
            this.screenShareAudioConsumer = null;
          }

          this.screenShareVideoConsumerRemovedSubject.next(socketId);
          this.screenShareAudioConsumerRemovedSubject.next(socketId);
        }
        
      }
    }

    if(this.producerIdSocketIdMap.has(producerId)){
      this.producerIdSocketIdMap.delete(producerId);
    }

    this.participantProducerRemovedSubject.next({socketId, producerId, kind})
  }

  async loadMediasoupDevice(routerRtpCapabilities) {
    try {
      this.mediasoupDevice = new Device();
    } catch (error) {
      if (error.name === 'UnsupportedError') {
        console.error('browser not supported');
      }
    }
    await this.mediasoupDevice.load({ routerRtpCapabilities });
  }

  isMediasoupControlSocketConnected() {
    return !!this.rxdbDb;
  }

  async publishToMediasoupRoom(track: MediaStreamTrack, screenShare: boolean = false, recepientSocketId?: string, simulcast = true, paused = false, manuallyPaused = false) {
    if(!track){
      return;
    }

    if(!this.createProducerTransportPromise){
      this.createProducerTransportPromise = this.createProducerTransport();
    }

    try{
      this.mediasoupProducerTransport = await this.createProducerTransportPromise;
    }
    catch(error){
      console.error('error creating producer transport');
      console.error(error);
      throw error;
    }

    const encodings = track.kind == 'video' && simulcast && !screenShare ? [
      { maxBitrate: 125 * 1024/*, scaleResolutionDownBy: 2*/ },
      { maxBitrate: 350 * 1024 },
      { maxBitrate: 1000 * 1024 }
    ] : undefined;
    
    const trackParams = { track: track, appData: { recepientSocketId, screenShare, paused, manuallyPaused }, encodings, zeroRtpOnPause: true, disableTrackOnPause: false, stopTracks: false };
      // if(track.kind == 'video' && simulcast){
      //   // else{
      //   //   trackParams.encodings = [
      //   //     // { maxBitrate: 1000 * 1024 },
      //   //     { maxBitrate: 2000 * 1024 },
      //   //     { maxBitrate: 4000 * 1024 }
      //   //   ];
      //   // }
      //   // trackParams.encodings = [
      //   //   // { maxBitrate: !screenShare ? 100000 : 500000, scaleResolutionDownBy: !screenShare ? 2 : 1.5},
      //   //   // { maxBitrate: !screenShare ? 300000 : 900000, scaleResolutionDownBy: !screenShare ? 1.5 : 1 },
      //   //   // { maxBitrate: !screenShare ? 900000 : 1800000, scaleResolutionDownBy: 1 }
  
      //   //   { maxBitrate: !screenShare ? 125 * 1024 : undefined, scaleResolutionDownBy: !screenShare ? 2 : 1.5},
      //   //   { scaleResolutionDownBy: !screenShare ? 1.5 : 1.25 },
      //   //   { scaleResolutionDownBy: 1 }
      //   // ];
      // }
  
      
  
      const producer = await this.mediasoupProducerTransport.produce(trackParams);
  
      if(producer && producer.id)
      {
        // if(producer.kind == 'video' && !screenShare && !recepientSocketId){
        //   if(!this.producerRequested[producer.id]){
        //     producer.pause();
        //   }
        //   //tracks[0].enabled = false;
        // }
        if(paused){
          //it would have already been paused on the server due to the parameters passed during server produce
          this.pauseProducerLocally(producer, true);
        }
  
        if(producer.kind == 'audio'){
          //debugger;
          producer.observer.on('pause', () => {
            console.log('audio paused');
          });
  
          if(screenShare){
            this.screenShareAudioProducer = producer;
          }
          else{
            this.audioProducer = producer;
          }
        }
        else if(producer.kind == 'video'){
          if(screenShare){
            this.screenShareVideoProducer = producer;
          }
          else{
            this.videoProducer = producer;
          }
        }
        
        producer.track.addEventListener('ended', () => {
          this.closeProducer(producer);
        });
        
        //this.localMediasoupProducers.push(producer);
        console.log('Producer id: ' + producer.id);
  
        return producer;
      }
      else{
        return null;
      }    
    
  }

  // @HostListener('window:beforeunload')
  // @HostListener('window:unload')
  // onBeforeUnload(){
  //   this.sendMediasoupControlRequest('disconnect', {});
  // }
  // async waitForHeartbeatToStop(){
  //   while(this.heartbeatTimeout != null){
  //     await this.util.sleep(50);
  //   }
  // }

  startHeartbeat(){
    const meetingId = this.meetingid;
    const heartbeatFunction = async () => {
      const start = new Date().getTime();
      //if we don't receive a response in 5 seconds, then we know we are offline
      try{
        //console.log('starting heartbeat');
        const ret = await this.sendMediasoupControlRequest('no-op', {});
        //console.log('finished heartbeat');
        // if(ret !== true){
        //   this.handleDisconnected(true, false);
        //   this.heartbeatTimeout = null;
        //   return;
        // }

        const end = new Date().getTime();

        const responseTime = end - start;

        let timeBeforeNextHeartbeat = this.timeBetweenHeartbeatsMs - responseTime;

        if(timeBeforeNextHeartbeat < 0){
          timeBeforeNextHeartbeat = 0;
        }

        //if(!this.production){
          // console.log(`heartbeat response time (ms): ${responseTime}`);
          // console.log(`time before next heartbeat (ms): ${timeBeforeNextHeartbeat}`);
        //}

        //if no-op returned successfully, then we are ok. sleep for the remaining time and send again
        //debugger;
        if(!this.leaving){
          this.heartbeatTimeout = setTimeout(heartbeatFunction, timeBeforeNextHeartbeat);
        }
        else{
          this.heartbeatTimeout = null;
        }  
      }
      catch(error){
        console.error(error);

        if(!this.rxdbDb || this.rxdbDb.destroyed){
          this.heartbeatTimeout = null;
          return;
        }

        if(!this.leaving){
          this.heartbeatTimeout = setTimeout(heartbeatFunction, this.timeBetweenHeartbeatsMs);
        }
        else{
          this.heartbeatTimeout = null;
        }
        //i don't expect any errors in heartbeat because I've asked it to just return false on timeout. 
        //So if we are here, then there's something more nefarious wrong than a timeout, so we do a hard reconnect
        //this.handleDisconnected();
        return;
      }
    }

    this.heartbeatTimeout = setTimeout(heartbeatFunction, 2000);//start initial heartbeat in 2 seconds
  }

  async subscribeToMediasoupRoom(room: string | null = null, participantData: any = null) {
    //let mediasoupConsumerTransport = this.mediasoupConsumerTransport;
    if (!this.ready) {
      //const socket = await this.connectMediasoupControlSocket(room, participantData);
      const connected = await this.connectMediasoupControlSocket(room, participantData);
      this.meParticipant.socketid = this.clientInstanceId;

      
      this.startHeartbeat();
      // if(!this.noopinterval){
      //   this.noopinterval = setInterval(() => {
      //     //if(this.socket){
      //       this.sendMediasoupControlRequest('no-op', {});//to prevent timing out
      //     //}
      //   }, 1000);
      // }

      if(!connected){
        throw new Error('Couldn\'t find a server to connect to.');
      }

      //const mythis = this;
      this.subscribeToCommand('connect', (evt) => {
        console.log('socket.io connected()');
      });
      

      this.subscribeToCommand('disconnect', async (evt) => {
        //debugger
        //if (!mythis.reconnecting) {
          //this is triggered from socket.io locally when connection is shut off
        console.log('socket.io disconnect:', evt);

          await this.handleDisconnected('');
        //}
      });

      // this.subscribeToCommand('_disconnect', async (evt) => {
      //   //debugger
      //   //if (!mythis.reconnecting) {
      //     //this is sent from the server
      //     console.log('socket.io disconnect:', evt);

      //     await this.handleDisconnected();
      //   //}
      // });

      //note: it is possible for both disconnect and _disconnect to be received when using a socket.io client
      
      this.subscribeToCommand('error', (err) => {
        //debugger
        console.error('socket ERROR:', err);
        setTimeout(async () => {
          if (!this.leaving && !this.allowAutoReconnect) {
            console.log('calling connect to meeting until successful from this.subscribeToCommand(\'error\')');
            await this.connectToMeetingUntilSuccessful(false, this.currentRoom, this.meParticipant.data);
          }
          //alert('Websocket error.');
          //document.location.reload();
          //publishVideo();
        }, 1000);
      });

      this.subscribeToCommand('newPeer', async (message) => {
        if(this.ready){
          if(message.socketid != this.clientInstanceId){
            this.addParticipant(message.socketid, message.username, message.name, message.host, message.photourl, message.data);
            const currentProducersInfo = message.currentProducersInfo;
            for(let i = 0; i < currentProducersInfo.length; i++){
              const producerInfo: {producerId, kind, screenShare, private} = currentProducersInfo[i];
              await this.addParticipantProducer(message.socketid, producerInfo.producerId, producerInfo.kind, producerInfo.screenShare, producerInfo.private);
            }
          }
          else{
            let roomChanged = false;
            if(this.currentRoom != message.room){
              roomChanged = true;
              this.currentRoom = message.room;
            }
            if(roomChanged || !message.late){//I think startRoom should ideally only be called if the room changes. However, before introducing late notifications, startRoom would be called every time (which I think is a bug). So this is just to maintain the same functionality.
              //await mythis.removeAllParticipants();
              await this.startRoom();
            }
          }
        }
      });

      // this.subscribeToCommand('allPeers', (allPeers) => {
      //   this.allPeers = allPeers;
      // });

      this.subscribeToCommand('allPeersCount', (message) => {
        //this is the count of all participants in all rooms
        this.allParticipantsCount = message.count;
      })

      // this.subscribeToCommand('roomChanged', async (message) => {
        
      //   //add all participants in your new room
      // });
      
      this.subscribeToCommand('newProducer', async (message) => {
        console.log('socket.io newProducer:', message);
        const remoteId = message.socketId;
        const prdId = message.producerId;
        const kind = message.kind;
        const screenShare = message.screenShare;
        const privateProducer = message.private;

        if(kind == 'video' && screenShare && this.screenShareAvailable && remoteId != this.clientInstanceId){
          await this.stopScreenShare();
        }
        //const {remoteId, prdId, kind, screenShare} = message;

        if(remoteId != this.clientInstanceId){
          if(this.ready){
            await this.addParticipantProducer(remoteId, prdId, kind, screenShare, privateProducer);
          }
        }

      });

      this.subscribeToCommand('producerClosed', async (message) => {
        //console.log('socket.io producerClosed:', message);
        //const localId = message.localId;
        const remoteId = message.remoteId;
        const kind = message.kind;
        //const consumerId = message.consumerId;
        const producerId = message.producerId;
        //console.log('--try removeConsumer remoteId=%s, producerId=%s, track=%s', remoteId, producerId, kind);
        //mythis.removeMediasoupConsumer(remoteId, consumerId);
        //mythis.removeRemoteVideo(remoteId);
        //await mythis.removeProcteeProducer(remoteId, )
        await this.removeParticipantProducer(remoteId, producerId, kind);
      });

      this.subscribeToCommand('producerPaused', async (message) => {
        //console.log('socket.io producerPaused:', message);
        if(this.clientInstanceId == message.remoteId){
          let producerId = message.producerId;
          const producers = [this.audioProducer, this.videoProducer, this.screenShareVideoProducer, this.screenShareAudioProducer];
          for(let i = 0; i < producers.length; i++){
            if(producers[i] && producers[i].id == producerId){
              this.pauseProducerLocally(producers[i], message.manuallyPaused);
              // this.localMediasoupProducers[i].pause();
              // if(this.meParticipantAudioComponent.audioConsumer.id == this.localMediasoupProducers[i].id){
              //   // this.audioAvailable = false;
              //   // this.meParticipant.audioAvailable = false;
              //   this.setProducerTypeUnavailable()
              // }
            }
          }
        }
        else{
          const participant = this.findParticipant(message.remoteId);
          if(participant){
            const audioConsumer = this.getAudioConsumer(participant.audioProducerId!);
            if(participant && (!audioConsumer || audioConsumer.producerId == message.producerId)){
              // participant.participantAudioComponent.audioConsumer.pause();
              participant.audioAvailable = false;
            }
          }
          //mythis.remoteProducerPausedSubject.next(message);
        }
      });

      this.subscribeToCommand('producerResumed', async (message) => {
        //console.log('socket.io producerResumed:', message);
        if(this.clientInstanceId == message.remoteId){
          let producerId = message.producerId;
          this.producerRequested[producerId] = true;
          const producers = [this.audioProducer, this.videoProducer, this.screenShareVideoProducer, this.screenShareAudioProducer];
          for(let i = 0; i < producers.length; i++){
            if(producers[i] && producers[i].id == producerId){
              this.resumeProducerLocally(producers[i]);
              //this.localMediasoupProducers[i].resume();
            }
          }
        }
        else{
          const participant = this.findParticipant(message.remoteId);
          if(participant){
            //debugger;
            const audioConsumer = this.getAudioConsumer(participant.audioProducerId!);
            if(participant && audioConsumer && audioConsumer.producerId == message.producerId){
              //participant.participantAudioComponent.audioConsumer.resume();
              participant.audioAvailable = true;
            }
          }
          //mythis.remoteProducerResumedSubject.next(message);
        }
      });

      this.subscribeToCommand('peerDisconnected', async (message) => {//this is called when a peer disconnects
        console.log('socket.io peerDisconnected', message);
        if(message.remoteId == this.clientInstanceId){
          
        }
        else{
          this.removeParticipant(message.remoteId);      
        }
      });

      this.subscribeToCommand('peerLeft', async (message) => {//this is called when a peer leaves the room
        console.log('peerLeft', message);
        if(message.remoteId == this.clientInstanceId){//if i am the one leaving a room, remove all participants
          //remove all producers
          //no need to removeAllParticipants here because it will be done in 'newPeer'+
          //await mythis.removeAllParticipants();
        }
        else{
          await this.removeParticipant(message.remoteId);      
        }
      });

      this.subscribeToCommand('peerEnteredRoom', (message) => {
        //for hosts only
        
        const {room, socketid, username} = message;

        if(this.clientInstanceId != socketid){
          this.peerEnteredRoom(room, username);
        }
      });

      this.subscribeToCommand('peerLeftRoom', (message) => {
        //for hosts only
        const {room, socketid, username} = message;
        this.peerLeftRoom(room, username);
      });

      
      this.subscribeToCommand('handRaised', (message) => {
        const socketId = message.socketId;
        if(socketId == this.clientInstanceId){
          this.handraised = true;
        }
        else{
          if(this.participantsMap.has(socketId)){
            const participant = this.participantsMap.get(socketId);
            participant!.handRaised = true;
            this.participantHandRaisedSubject.next(participant!);
          }
        }
      });

      this.subscribeToCommand('handDropped', (message) => {
        this.handDroppedSubject.next(message);
      });

      this.subscribeToCommand('setLockStatus', (message) => {
        //debugger;
        if(message.socketid == this.clientInstanceId){
          this.meParticipant.unlocked = message.unlocked;
          if(this.meParticipant.unlocked){
            this.notifySubject.next({messageid: NotificationMessages.enabledtospeak});
          }
        }
        else{
          if(this.participantsMap.has(message.socketid)){
            this.participantsMap.get(message.socketid)!.unlocked = message.unlocked;
          }
        }
      });

      

      this.subscribeToCommand('takeAttendance', (message) => {
        if(!this.localuserinfo.host && !this.cloudRecorder)
        {
          this.takeAttendanceSubject.next(null);
        }
      });

      this.subscribeToCommand('chatMessage', (message) => {
        if(message.socketid != this.clientInstanceId){
          //const participant = this.getMessageParticipant(message);
          this.addChat({ senderusername: message.username, message: message.message, sendername: message.name, senderphotourl: message.photourl, sendersocketid: message.socketid }, false);
        }
      });

      this.subscribeToCommand('customMessage', (message: { messageId: string, socketId: string, message: any, private: boolean }) => {
        this.customMessageSubject.next(message);
      });

      this.subscribeToCommand('meetingEnded', ({endingSocketId}) => {
        this.meetingEndedSubject.next(endingSocketId);
      })

      this.subscribeToCommand('disconnectedByHost', async () => {
        //await this.leaveMeeting();
        this.leaveMeetingSubject.next();
      })

      // --- get capabilities --
      const routerdata = await this.sendMediasoupControlRequest('getRouterRtpCapabilities', {});
      console.log('getRouterRtpCapabilities:', routerdata);
      await this.loadMediasoupDevice(routerdata);
    }

    if(this.localuserinfo.host){
      const roomSocketIds: any = await this.sendMediasoupControlRequest('getAllCurrentPeersByRoom', {});
      for(let room in roomSocketIds){
        for(let socketid in roomSocketIds[room]){
          this.peerEnteredRoom(room, roomSocketIds[room][socketid].username);
        }
      }
    }

    this.ready = true;
    this.sendMediasoupControlRequest('ready', {});
    await this.startRoom();
  }

  private async handleDisconnected(timedOutCommandId: string) {
    if(timedOutCommandId){
      const timedOutCommand = await this.locallyInitiatedCommandsCollection.findOne(timedOutCommandId).exec();
      if(!timedOutCommand || timedOutCommand.socketid != this.clientInstanceId){
        console.warn(`not timing out because command '${timedOutCommandId}' is obsolete`);
        return;
      }
      else{
        console.log(`timed out on command`);
      }
    }
    //debugger;
    //we are sending this to the server so that in the case where the disconnect is triggered by some logic here (e.g.) unable to send heartbeat (primarily for rxdb connections, we can notify the server that we are going away)
    
    if (!this.leaving) {
      console.log('not leaving yet...attempting to reconnect');
      
      try {
        this.meetinginfo = await this.meetingservice.getMeeting(this.meetingid!).toPromise() ?? undefined; //try to update the meeting so we know if the meeting has now ended...if we aren't able to update it, proceed as normal
      }
      catch (error) {
        console.warn(error);
        //we will simply use the existing meetinginfo object
      }

      if (this.meetinginfo?.ended) {
        console.log('meeting has ended so will redirect');
      }
      else {
        
        //hard reconnect involves full disconnection which assumes the server has already disconnected us
        this.addedCurrentProducers = false;
        
        if (!this.leaving) {
          
          console.log('manually disconnecting inside hardReconnectIfReconnecting');
          try{
            await this.disconnect();
          }
          catch(error){
            console.error(error);
          }
          if (this.allowAutoReconnect) {
            
            console.log('deassigning meeting server and reconnecting');
            //console.log('calling connect to meeting until successful from this.hardReconnectIfReconnecting()');
            await this.meetingservice.deassignMeetingServer(this.meetingid!, this.jwt, this.clientInstanceId, false);//just in case the server is reachable
            
            //this.generateClientInstanceId();
            console.log('calling connect to meeting until successful from this.hardReconnectIfReconnecting()');
            await this.connectToMeetingUntilSuccessful(false, this.currentRoom!, this.meParticipant.data);
            
          }
          
        }
        
        
      }
    }
    else{
      await this.disconnect();
    }
  }

  goToDisconnected() {
    //throw new Error("Method not implemented.");
    document.location.reload();
  }

  async restartConsumerTransportIce(){
    const iceParameters = await this.sendMediasoupControlRequest('restartConsumerTransportIce', {});
    await this.mediasoupConsumerTransport.restartIce({iceParameters});
  }

  async restartProducerTransportIce(){
    const iceParameters = await this.sendMediasoupControlRequest('restartProducerTransportIce', {});
    await this.mediasoupProducerTransport.restartIce({iceParameters});
  }

  async startRoom() {
    console.log('starting room');
    //this.participantsMap = new Map<string, Participant>();
    await this.removeAllParticipants();
    this.screenShareAvailable = false;
    this.screenShareVideoConsumer = null;
    this.screenShareVideoParticipantProducerId = null;
    this.screenShareParticipantSocketId = null;
    this.screenShareParticipantUsername = null;
    this.screenShareParticipantName = null;
    this.participantScreenShareAvailable = false;
    await this.consumeCurrentMediasoupProducers();
    // await this.startChat();
    console.log(`current room: ${this.currentRoom}`);
    this.enteredRoomSubject.next();
    this.notifySubject.next({messageid: NotificationMessages.enteredroom, data: {room: this.currentRoom}});
  }

  async createProducerTransport(){
    try{
      if (this.mediasoupProducerTransport) {
        try {
          console.log('closing old producer transport');
          this.mediasoupProducerTransport.close();
        }
        catch (error) {
          console.log(error);
        }
      }
      // --- get transport info ---
      console.log('--- createProducerTransport --');
      const params = await this.sendMediasoupControlRequest('createProducerTransport', {});
      console.log('transport params:', params);
      this.mediasoupProducerTransport = this.mediasoupDevice.createSendTransport(params);
      console.log('createSendTransport:', this.mediasoupProducerTransport);

      // --- join & start publish --
      this.mediasoupProducerTransport.on('connect', async ({ dtlsParameters }, callback, errback) => {
        console.log('--trasnport connect');
        this.sendMediasoupControlRequest('connectProducerTransport', { dtlsParameters: dtlsParameters })
          .then(callback)
          .catch(errback);
      });

      this.mediasoupProducerTransport.on('produce', async ({ kind, rtpParameters, appData }, callback, errback) => {
        console.log('--trasnport produce');
        try {
          const recepientSocketId = appData.recepientSocketId;
          const screenShare = appData.screenShare;
          const { id, message }: any = await this.sendMediasoupControlRequest('produce', {
            transportId: this.mediasoupProducerTransport.id,
            kind,
            rtpParameters,
            appData,
            recepientSocketId,
            screenShare,
            paused: appData.paused,
            manuallyPaused: appData.manuallyPaused
          });
          if(message){
            this.notifySubject.next({messageid: NotificationMessages.errorproducing, data: message});
          }
          callback({ id });
          //console.log('--produce requested, then subscribe ---');

        } catch (err) {
          errback(err);
        }
      });

      this.mediasoupProducerTransport.on('connectionstatechange', async (state) => {
        const _restartProducerTransportICEFunction = async () => {
          for(let i = 0; i < this.maxCommandRetries; i++){
            try{
              await this.restartProducerTransportIce();
              break;
            }
            catch(e){
              console.warn(`Error restarting producer transport ice: `, e);
              if(i < this.maxCommandRetries - 1){
                console.log('will try again');
              }
              else{
                this.handleDisconnected('');
              }
            }
          }
        };
        //let stream;
        switch (state) {
          case 'connecting':
            console.log('publishing...');
            break;
          case 'connected':
            console.log('published');
            if(!this.connecting){
              this.connectionRestoredSubject.next();
            }
            break;
          case 'disconnected':
            if(!this.leaving){
              console.log('producer transport disconnected. restarting ice');
              _restartProducerTransportICEFunction();
              this.unstableConnectionSubject.next();
            }
            //await this.closeProducerTransport();
            //if (started) {
            //  mediasoupProducerTransport.close();
            //  stream = await getVideoStream();
            //  publishToMediasoupRoom(stream);
            //}
            break;
          case 'failed':
            if(!this.leaving){
              console.log('producer transport failed. restarting ice');
              _restartProducerTransportICEFunction();
            }
            //alert('Unable to stream. Please refresh the page to try again');
            break;
          case 'closed':
            console.log('consumer transport closed');
            break;
          default:
            console.log('unknown state: ' + state);
            break;
        }
      });

      this.mediasoupProducerTransport.observer.on('close', () => {
        this.createProducerTransportPromise = null;
      });

      return this.mediasoupProducerTransport;
    }
    catch(error){
      this.createProducerTransportPromise = null;//the attempt failed, so we have to remove the saved promise so that the next attempt creates a new promise
      throw error;
    }
  }

  async createConsumerTransport(){
    try{
      if (this.mediasoupConsumerTransport) {
        try {
          console.log('closing old consumer transport');
          this.mediasoupConsumerTransport.close();
        }
        catch (error) {
          console.log(error);
        }
      }
      const params = await this.sendMediasoupControlRequest('createConsumerTransport', {});
      console.log('transport params:', params);
      this.mediasoupConsumerTransport = this.mediasoupDevice.createRecvTransport(params);
      console.log('createConsumerTransport:', this.mediasoupConsumerTransport);

      // --- join & start publish --
      this.mediasoupConsumerTransport.on('connect', async ({ dtlsParameters }, callback, errback) => {
        console.log('--consumer trasnport connect');
        this.sendMediasoupControlRequest('connectConsumerTransport', { dtlsParameters: dtlsParameters })
          .then(callback)
          .catch(errback);
      });

      this.mediasoupConsumerTransport.on('connectionstatechange', async (state) => {
        const _restartConsumerTransportICEFunction = async () => {
          for(let i = 0; i < this.maxCommandRetries; i++){
            try{
              await this.restartConsumerTransportIce();
              break;
            }
            catch(e){
              console.warn(`Error restarting consumer transport ice: `, e);
              if(i < this.maxCommandRetries - 1){
                console.log('will try again');
              }
              else{
                this.handleDisconnected('');
              }
            }
          }
        };
        //debugger
        switch (state) {
          case 'connecting':
            console.log('consumer transport connecting...');
            break;
          case 'connected':
            console.log('consumer transport connected');
            if(!this.connecting){
              this.connectionRestoredSubject.next();
            }
            //consumeCurrentProducers(clientId);
            break;
          case 'disconnected':
            if(!this.leaving){
              console.log('consumer transport disconnected. restarting ice');
              _restartConsumerTransportICEFunction();
              this.unstableConnectionSubject.next();
            }
            //alert('Connection Lost');
            //await this.accessCodeEntered(this.accesscode);
            break;
          case 'failed':
            if(!this.leaving){
              console.log('consumer transport failed. restarting ice');
              _restartConsumerTransportICEFunction();
            }
            //TODO: what happens if it fails
            break;
          case 'closed':
            console.log('consumer transport closed');
            break;
          default:
            console.log(`unexpected consumer transport state: ${state}`);
            break;
        }
      });

      this.mediasoupConsumerTransport.observer.on('close', () => {
        console.log('consumer transport closed');
        this.createConsumerTransportPromise = null;
      });
      
      return this.mediasoupConsumerTransport;
    }
    catch(error){
      this.createConsumerTransportPromise = null;//the attempt failed, so we have to remove the saved promise so that the next attempt creates a new promise
      throw error;
    }
  }

  

  subscribeToCommand(event: string, subscriberFunction: any){
    //console.log(`subscribing to '${event}'`);
    this.commandSubscribers[event] = subscriberFunction;
  }

  unsubscribeFromCommand(event: string){
    //console.log(`unsubscribing from '${event}'`);
    if(this.commandSubscribers[event]){
      delete this.commandSubscribers[event];
    }
  }

  socketSNo = 0;

  async connectRxdbReplicationWebsocket(domain: MeetingDomain, clientInstanceId: string, jwt: string){
    //console.trace('connecting replication websocket');
    const initSNo = this.initSNo;
    let first = true;
    let socket;
    const connectSocket = () => {
      if(socket){
        socket.off();
      }
      if(this.rxdbReplicationSocket){
        this.rxdbReplicationSocket.off();
      }

      this.socketSNo++;

      socket = io.connect(`${environment.mediasoupScheme}://${domain.domain}:${domain.port}?`, {transports: ['websocket'], query: {reconnection: false, clientInstanceId, forRxdb: true, jwttoken: jwt } });
      
      socket.sNo = this.socketSNo;

      return new Promise<void>((resolve, reject) => {
        
        socket.on('connect', () => {
          socket.off('connect');
          console.log(`websocket ${socket.sNo} connected: `, socket);
          
          // else{
          this.rxdbReplicationSocket = socket;

          if(!first){
            this.remoteCommandsPullStream$!.next('RESYNC');
            this.localCommandsPullStream$!.next('RESYNC');
          }

          //}
          if(first){
            resolve();
          }

          if(first){
            first = false;
          }

          this.connectionRestoredSubject.next();
        });
        socket.on('disconnect', () => {
          //doing this here because socket.on('disconnect') appears to fire multiple times for the same socket
          socket.off();

          console.warn(`websocket ${socket.sNo} disconnected: `, socket);
          if(!this.leaving && !this.connecting && initSNo == this.initSNo){
            console.log(`reconnecting in socket ${socket.sNo} disconnect`);
            this.unstableConnectionSubject.next();
            connectSocket();
          }
        });
        socket.on('error', () => {
          socket.off();
          console.error(`websocket ${socket.sNo} error`);
          if(first){
            reject();
          }
          else{
            if(!this.leaving && initSNo == this.initSNo){
              console.log(`reconnecting in socket ${socket.sNo} error`);
              connectSocket();
            }
          }
        });
        socket.on('connect_failed', () => {
          socket.off();
          console.error(`websocket ${socket.sNo} connection failed`);
          if(first){
            reject();
          }
          else{
            if(!this.leaving && initSNo == this.initSNo){
              console.log(`reconnecting in socket ${socket.sNo} connection_failed`);
              connectSocket();
            }
          }
        });
        socket.on('rxdb_server_command', (command: any) => {
          //when a command is received by the socket, push it to the pullStream
          //console.log('remote command received: ', command);
          //debugger;
          this.remoteCommandsPullStream$!.next(command);
        });
        socket.on('rxdb_client_command', (command: any) => {
          //console.log('local command updated on server: ', command);
          //debugger;
          this.localCommandsPullStream$!.next(command);
        });
        //return resolve();
      });
    }

    await connectSocket();
  }

  async connectMediasoupControlSocket(room: string | null = null, participantData: any = null) {
    //debugger;
    //if (this.rxdbDb) {
      await this.disconnect();
      //this.clientId = null;
    //}
    const mythis = this;
    
    const connect = (domain: MeetingDomain): Promise<boolean> => {
      return new Promise<boolean>(async (resolve, reject) => {
        let rejected = false;
        let resolved = false;
        const _resolve = function (){
          resolved = true;
          resolve(true);
        }
        const _reject = function(reason: any){
          rejected = true;
          reject(reason);
        }
        //let socket;
        try{
          

          //console.log('creating pull streams');
          
          //let rxdbReplicationSocket;
          await this.connectRxdbReplicationWebsocket(
            domain, 
            this.clientInstanceId,
            mythis.jwt
          );

          // setInterval(() => {
          //   remoteCommandsPullStream$.next('RESYNC');
          //     localCommandsPullStream$.next('RESYNC');
          // }, 2000);
          
          

          mythis.reconnecting = false;
          this.subscribeToCommand('welcome', (message) => {
            
            //debugger;
            //mythis.clientId = message.id;
            console.log('connected to server');
            mythis.currentRoom = message.room;
            mythis.setDefaultBreakoutRoomIfNotSet(mythis.meParticipant.username, mythis.currentRoom ?? '');
            //mythis.meetingChat.startMessaging();
            _resolve();
          });

          this.subscribeToCommand('error', function (err) {
            //debugger
            console.error('socket.io ERROR:', err);
            setTimeout(async function () {
              // if (!mythis.leaving) {
              //   await mythis.connectToMeetingUntilSuccessful();
              // }
              //alert('Websocket error.');
              //document.location.reload();
              //publishVideo();
            }, 1000);
            _reject(err);
          });

          this.subscribeToCommand('serverCapacityExceeded', async () => {
            console.log('server capacity exceeded at ', domain);
            await mythis.generateClientInstanceId();
            _reject('serverCapacityExceeded');
            //this.toastr.error('Maximum Participants Exceeded');
            //this.router.navigate([`/join/${this.meetingid}`], { queryParams: {auth: this.jwt}});
          });
          this.subscribeToCommand('serverStopping', async () => {
            console.log(`server stopping at `, domain);
            await mythis.generateClientInstanceId();
            _reject('serverStopping');
          });
          this.subscribeToCommand('connect_error', (err) => {
            //debugger;
            console.error(err);
            _reject(err);
          });
          this.subscribeToCommand('connect_failed', (err) => {
            //debugger;
            console.error(err);
            _reject(err);
          });
          this.subscribeToCommand('connect_timeout', (timeout) => {
            //debugger;
            console.error('Timeout: ' + timeout);
            _reject('timeout');
          });

          await this.sendMediasoupControlRequest('rxDbConnect', { clientInstanceId: this.clientInstanceId, jwttoken: mythis.jwt, room: room ?? mythis.currentRoom, data: JSON.stringify(participantData) });
        }
        catch(error){
          console.error(error);
          _reject(error);
        }
      });
    };
    
    if(this.domain){
      try{
        const connected = await connect(this.domain);
        console.log('Connected to server at ', this.domain);
        return connected;
      }
      catch(error){
        console.log('Unable to connect to server at ', this.domain);
        console.log(error);
        throw new Error('Unable to connect to meeting');
      }
      //let socket = mythis.socket;
    }
    //if we get here, then it means we couldn't find a server to connect to
    return false;
  }

  async removeAllParticipants() {
    const promises: Promise<void>[] = [];
    for (const [socketId, participant] of this.participantsMap) {
      promises.push(this.removeParticipant(socketId));
    }
    await Promise.all(promises);
    this.allParticipantsRemovedSubject.next();
  }

  findParticipantRoom(username: string){
    for(let [room, participantsocketids] of this.roomParticipantsMap){
      if(participantsocketids.has(username)){
        return room;
      }
    }
    return null;
  }

  peerEnteredRoom(room: string, username: string) {
    if (!this.roomParticipantsMap.has(room)) {
      this.roomParticipantsMap.set(room, new Set<string>());
    }
    if(!this.roomParticipantsMap.get(room)!.has(username)){
      this.roomParticipantsMap.get(room)!.add(username);
    }

    this.setDefaultBreakoutRoomIfNotSet(username, room);

    this.peerEnteredRoomSubject.next({room, username});
  }

  private setDefaultBreakoutRoomIfNotSet(username: string, room: string) {
    let specifiedbreakoutroom: string | undefined = undefined;
    for (let [room, usernames] of this.breakoutRooms) { //if the user is not specified to breakout into any room, set his breakout room to the room he just entered
      if (usernames.has(username)) {
        specifiedbreakoutroom = room;
      }
    }

    if (!(specifiedbreakoutroom ?? false)) {
      if (!this.breakoutRooms.has(room)) {
        this.breakoutRooms.set(room, new Set<string>());
      }
      this.breakoutRooms.get(room)!.add(username);
    }
  }

  peerLeftRoom(room: string, username: string) {
    if (this.roomParticipantsMap.has(room) && this.roomParticipantsMap.get(room)!.has(username)) {
      this.roomParticipantsMap.get(room)!.delete(username);
    }
  }

  

  async consumeCurrentMediasoupProducers() {
    console.log('current client id: ', this.clientInstanceId);
    console.log('-- try consumeAll() --');
    const remoteInfo: any = await this.sendMediasoupControlRequest('getCurrentProducers', { localId: this.clientInstanceId });
    //console.log('remoteInfo.producerIds:', remoteInfo.producerIds);
    //console.log('remoteInfo.remoteVideoIds:', remoteInfo.remoteVideoIds);
    //console.log('remoteInfo.remoteAudioIds:', remoteInfo.remoteAudioIds);

    
    this.addCurrentProducers(remoteInfo.remoteVideoIds, remoteInfo.remoteAudioIds, remoteInfo.screenShareVideoProducerId, remoteInfo.screenShareAudioProducerId, remoteInfo.ids);
    this.addedCurrentProducers = true;
//    this.reconnecting2 = false;
  }

  addCurrentProducers(remoteVideoIds, remotAudioIds, screenShareVideoProducerId, screenShareAudioProducerId, mediasoupSocketIds) {
    //console.log('remote VideoIds: ', remoteVideoIds);
    console.log('----- consumeAll() -----');

    //this.mediasoupSocketIds = ids;

    for(const socketid in mediasoupSocketIds){
      if(socketid != this.clientInstanceId){
        this.addParticipant(socketid, mediasoupSocketIds[socketid].username, mediasoupSocketIds[socketid].name, mediasoupSocketIds[socketid].host, mediasoupSocketIds[socketid].photourl, mediasoupSocketIds[socketid].data);
      }
    }

    //this.goToPage(1);

    remoteVideoIds.forEach(remoteVideo => {
      //this.consumeAddMediasoupTrack(transport, remoteVideo.socketId, remoteVideo.producerId, 'video');
      this.addParticipantProducer(remoteVideo.socketId, remoteVideo.producerId, 'video', screenShareVideoProducerId == remoteVideo.producerId, remoteVideo.private).catch((error) => {
        console.error(error);
      });//no point starting a screen share producer paused
    });
    remotAudioIds.forEach(remoteVideo => {
      //this.consumeAddMediasoupTrack(transport, remoteVideo.socketId, remoteVideo.producerId, 'audio');
      this.addParticipantProducer(remoteVideo.socketId, remoteVideo.producerId, 'audio', screenShareAudioProducerId == remoteVideo.producerId, remoteVideo.private).catch((error) => {
        console.error(error);
      });
    });
  }

  findParticipant(socketid): Participant | null {
    if (this.participantsMap.has(socketid)) {
      return this.participantsMap.get(socketid)!;
      //return this.participantsArray[index];
    }
    else {
      return null;
    }
  }

  async createConsumer(socketid: string, producerId: string, kind: string, screenShare: boolean, paused?: boolean, useProducerPausedState?: boolean) {
    if(!this.createConsumerTransportPromise){
      console.log('waiting for new transport');
      this.createConsumerTransportPromise = this.createConsumerTransport();
    }

    try{
      this.mediasoupConsumerTransport = await this.createConsumerTransportPromise;
    }
    catch(error){
      console.error('error creating consumer transport');
      console.error(error);
      throw error;
    }
    console.log(`creating '${kind}' consumer for '${socketid}', '${producerId}'`);
    const { rtpCapabilities } = this.mediasoupDevice;

    let data: any;

    for(let i = 0; i < this.maxCommandRetries; i++){
      //both commands below are retriable...so if it fails, and it is a timeout error we'll retry
      try{
        await this.sendMediasoupControlRequest('closeConsumersByType', {remoteId: socketid, prdId: producerId, kind: kind, screenShare: screenShare});
        data = await this.sendMediasoupControlRequest('consumeAdd', { rtpCapabilities: rtpCapabilities, remoteId: socketid, prdId: producerId, kind: kind, paused: paused === undefined ? true : paused, useProducerPausedState });
        break;
      }
      catch(error){
        console.error('Error creating consumer: ', error);

        if(error != 'timeout'){
          console.log('Not a timeout error so will rethrow exception');
          throw error;
        }

        if(i < this.maxCommandRetries - 1){
          console.log('will retry');
        }
        else{
          //todo: we need to confirm that the failure was due to timeout before reconnecting
          this.handleDisconnected('');
        }
      }
    }

    const {
      //producerIdLocal,
      id,
      kindLocal,
      rtpParameters,
    }: any = data;

    if (producerId && (producerId !== data.producerId)) {
      console.warn('producerID NOT MATCH');
    }

    let codecOptions = {};
    const consumer = await this.mediasoupConsumerTransport.consume({
      id,
      producerId,
      kind,
      rtpParameters,
      codecOptions,
    });
    if(paused){
      consumer.pause();
    }
    console.log(`${kind} consumer ${consumer.id} created`);

    if(kind == 'video'){
      this.videoConsumersMap.set(producerId, consumer);
    }
    else{
      this.audioConsumersMap.set(producerId, consumer);
    }
    
    return {consumer, data};
  }

  async closeConsumer(socketId: string, consumer: any, awaitServerPause?: boolean) {
    if (consumer) {
      if (consumer.closed) {
        return;
      }

      const _closeConsumerFunction = async () => {
        for(let i = 0; i < this.maxCommandRetries; i++){
          try{
            await this.sendMediasoupControlRequest('closeConsumer', { remoteId: socketId, kind: consumer.kind, consumerId: consumer.id, producerId: consumer.producerId });
            break;
          }
          catch(e){
            console.error(`Error closing consumer: `, e);
            if(i < this.maxCommandRetries - 1){
              console.log('will retry');
            }
          }
        }
      };

      const promise = _closeConsumerFunction();
      if (awaitServerPause) {
        await promise;
      }
      consumer.close();
      console.log('consumer ', {id: consumer.id, kind: consumer.kind}, ' for socket ', socketId, ' closed');
    }
  }

  async pauseConsumer(socketid: string, consumer: any, awaitServerPause?: boolean) {
    if (consumer) {
      if (consumer.closed) {
        return;
      }
      else{
        console.log(`pausing consumer `, { remoteId: socketid, kind: consumer.kind, consumerId: consumer.id, producerId: consumer.producerId });

        const _pauseConsumerFunction = async () => {
          for(let i = 0; i < this.maxCommandRetries; i++){
            try{
              await this.sendMediasoupControlRequest('pauseConsumer', { remoteId: socketid, kind: consumer.kind, consumerId: consumer.id });
              break;
            }
            catch(e){
              console.error(`error pausing consumer: `, e);
              if(i < this.maxCommandRetries - 1){
                console.log('will retry');
              }
            }
          }
        };

        const promise = _pauseConsumerFunction();
        
        if (awaitServerPause) {
          await promise;
        }

        consumer.pause();
      }
    }
  }

  // async pauseConsumers(consumersinfo: any[], awaitServerPause?: boolean){
  //   const data: any[] = [];
  //   consumersinfo.forEach(element => {
  //     data.push({remoteId: element.socketId, kind: element.consumer.kind, consumerId: element.consumer.id});
  //   });
  //   const promise = this.sendMediasoupControlRequest('pauseConsumers', data);
  //   if(awaitServerPause){
  //     await promise;
  //   }
  //   consumersinfo.forEach(element => {
  //     element.consumer.pause();
  //   });
  // }

  async resumeConsumer(socketid: string, consumer: any, awaitServerPause?: boolean) {
    if (consumer) {
      if (consumer.closed) {
        return;
      }
      else {
        console.log(`resuming consumer `, { remoteId: socketid, kind: consumer.kind, consumerId: consumer.id, producerId: consumer.producerId });

        const _resumeConsumerFunction = async () => {
          for(let i = 0; i < this.maxCommandRetries; i++){
            try{
              await this.sendMediasoupControlRequest('resumeConsumer', { remoteId: socketid, kind: consumer.kind, consumerId: consumer.id });
              break;
            }
            catch(e){
              console.error(`Error resumig consumer: `, e);
              if(i < this.maxCommandRetries - 1){
                console.log('will retry');
              }
            }
            
          }
        };

        const promise = _resumeConsumerFunction();
        
        if (awaitServerPause) {
          await promise;
        }

        consumer.resume();
      }
    }
  }

  // async resumeConsumers(consumersinfo: any[], awaitServerPause?: boolean){
  //   const data: any[] = [];
  //   consumersinfo.forEach(element => {
  //     data.push({remoteId: element.socketId, kind: element.consumer.kind, consumerId: element.consumer.id});
  //   });
  //   const promise = this.sendMediasoupControlRequest('resumeConsumers', data);
  //   if(awaitServerPause){
  //     await promise;
  //   }
  //   consumersinfo.forEach(element => {
  //     element.consumer.resume();
  //   });
  // }

  async pauseParticipantProducer(socketid: string, producerid: string, kind: string){
    await this.sendMediasoupControlRequest('pauseProducer', { remoteId: socketid, producerId: producerid, kind: kind });
  }

  async resumeParticipantProducer(socketid: string, producerid: string, kind: string){
    await this.sendMediasoupControlRequest('resumeProducer', { remoteId: socketid, producerId: producerid, kind: kind });
  }

  async closeParticipantProducer(socketid: string, producerid: string, kind: string){
    await this.sendMediasoupControlRequest('closeProducer', { remoteId: socketid, producerId: producerid, kind: kind });
  }

  async getMediasoupSocketIdUserDetails(mediasoupSocketId) {
    // let ret;
    // if (!this.mediasoupSocketIds[mediasoupSocketId]) {
    //   const obj: any = await this.sendMediasoupControlRequest('getUserDetails', { id: mediasoupSocketId });

    //   this.mediasoupSocketIds[mediasoupSocketId] = { username: obj.username, name: obj.name, host: obj.host, photourl: obj.photourl };
    // }

    // const obj: any = await this.sendMediasoupControlRequest('getUserDetails', { id: mediasoupSocketId });

    const ret: any = await this.sendMediasoupControlRequest('getUserDetails', { id: mediasoupSocketId });

    //ret = this.mediasoupSocketIds[mediasoupSocketId];

    console.log(`socket id ${mediasoupSocketId} => ${ret}`);

    return ret;
  }

  closeProducer(producer: any, stopTrack: boolean = true) {
    if(this.isMediasoupControlSocketConnected()){
      this.sendMediasoupControlRequest('closeProducer', { producerId: producer.id });//i don't think there's any need to await this
    }
    this.closeProducerLocally(producer, stopTrack);
  }

  closeProducerLocally(producer: any, stopTrack: boolean = true){
    producer.close();
    if(stopTrack){
      producer.track.stop();
    }
    // const index = this.localMediasoupProducers.indexOf(producer);
    // if(index != -1){
    //   this.localMediasoupProducers.splice(index, 1);
    // }
    // if (this.localMediasoupProducers.length == 0) {
    //   //no need to do this...its a bug in chrome 87 which should be fixed in 88.
    //   //this.closeProducerTransport();//no need to await this because its more important that the local transport is closed //it appears we have to close the producer transport because in some cases, when we close the mic, and then the camera, and then try to enable the camera afterwards, we get an error
    // }
    this.setProducerTypeUnavailable(producer);
  }

  private setProducerTypeUnavailable(producer: any) {
    if (this.localVideoTrack && producer.track.id == this.localVideoTrack.id) {
      this.videoAvailable = false;
      this.meParticipant.videoAvailable = false;
    }
    else if (this.localAudioTrack && producer.track.id == this.localAudioTrack.id) {
      this.audioAvailable = false;
      this.meParticipant.audioAvailable = false;
    }
    else if (this.screenShareVideoTrack && producer.track.id == this.screenShareVideoTrack.id) {
      this.screenShareAvailable = false;
    }
    this.producerTypeUnavailableSubject.next(producer);
  }

  async pauseProducer(producer: any, wait: boolean){
    const promise = this.sendMediasoupControlRequest('pauseProducer', { producerId: producer.id, kind: producer.kind });
    this.pauseProducerLocally(producer, true);
    if(wait){
      await promise;
    }
  }

  pauseProducerLocally(producer: any, manuallyPaused: boolean){
    producer.pause();
    if(manuallyPaused){
      this.setProducerTypeUnavailable(producer);
    }
  }

  resumeProducerLocally(producer: any){
    //this if is a failsafe to ensure that if a user has disabled audio, his audio producer should never be resumed...same for video
    if((producer.kind == 'audio' && this.settings.enableMicrophone) || (producer.kind == 'video' && this.settings.enableCamera) || producer.id == this.screenShareVideoProducerId || producer.id == this.screenShareAudioProducerId){
      producer.resume();
    }
    else{
      console.error(`Attempt to resume ${producer.kind} producer when user has disabled it`);
    }
  }

  async resumeProducer(producer: any){
    await this.sendMediasoupControlRequest('resumeProducer', { producerId: producer.id, kind: producer.kind });
    this.resumeProducerLocally(producer);
    
    //this.resumeProducerLocally(producer);
  }

  // async startChat(){
  //   this.chatmessages = [];
  //   const mythis = this;
  //   this.this.subscribeToCommand('chatMessage', function(message){
  //     if(message.socketid != mythis.socket.id){
  //       const participant = mythis.getMessageParticipant(message);
  //       mythis.addChat({ senderusername: message.username, message: message.message, sendername: participant.name, senderphotourl: participant.photourl }, false);
  //     }
  //   });
  //   //await this.getCurrentMessages();
  // }

  getMessageParticipant(message){
    let participant = new Participant();
      if(message.socketid == this.meParticipant.socketid){
        participant = this.meParticipant;
      }
      else if(this.participantsMap.has(message.socketid)){
        //const index = this.meeting.participantsMap.get(message.socketid);
        participant = this.participantsMap.get(message.socketid)!;
      }
      else{
        debugger;
      }
      return participant;
  }

  addChat(message: ChatMessage, bulk: boolean){
    this.chatmessages.push(message);
    this.chatAddedSubject.next({message, bulk});
  }

  async setPreferredConsumerSpatialLayer(remoteId, consumerId, preferredLayer){
    await this.sendMediasoupControlRequest('setConsumerPreferredSpatialLayer', { remoteId: remoteId, consumerId: consumerId, preferredLayer: preferredLayer });
  }

  async setConsumerPriority(remoteId, consumerId, priority){
    await this.sendMediasoupControlRequest('setConsumerPriority', { remoteId: remoteId, consumerId: consumerId, priority: priority });//no need to await this
  }

  // async recordMeeting(){
  //   if(this.localuserinfo.host){
  //     await this.meetingservice.recordMeeting(this.meetingid, this.jwt).toPromise();
  //     this.recording = true;
      
  //   }
  // }

  async leaveMeeting(ended: boolean = false){
    this.leaving = true;
    try{
      await this.handleDisconnected('');
    }
    catch{}
    try{
      this.localVideoTrack?.stop();
    }
    catch{}
    try{
      this.localAudioTrack?.stop();
    }
    catch{}
    try{
      this.screenShareVideoTrack?.stop();
    }
    catch{}
    try{
      this.screenShareAudioTrack?.stop();
    }
    catch{}
    try{
      this.selfieSegmentationCamera.stop();
      this.stopVideoStream();
    }
    catch{}
  }

  

  getParticipantByUsername(username): Participant{
    if(username == this.localuserinfo.username){
      return this.meParticipant;
    }
    else{
      let participant = this.participantsUsernameMap.get(username);
      if(!participant){
        participant = new Participant();
        participant.name = username;
        participant.username = username;
      }
      return participant;
    }
  }

  async moveParticipantsToRoom(room: string, usernames: string[], fromRoom: string | null = null){
    await this.sendMediasoupControlRequest('moveParticipantsToRoom', {room, usernames: usernames, fromRoom});
  }

  async breakIntoRooms(){
    const promises: Promise<unknown>[] = [];
    for(const [room, usernames] of this.breakoutRooms){
      //for(const username of usernames){
        const usernamesarray = [...usernames];
        promises.push(this.moveParticipantsToRoom(room, usernamesarray));
      //}
    }
    await Promise.all(promises);
    this.notifySubject.next({messageid: NotificationMessages.brokenout});
  }

  async raiseHand(){
    await this.sendMediasoupControlRequest('raiseHand', {});
  }

  async dropHand(remoteId = undefined){
    await this.sendMediasoupControlRequest('dropHand', {remoteId});
  }

  async pauseAllOtherAudioProducers(){
    await this.sendMediasoupControlRequest('pauseAllOtherAudioProducers', {});
  }

  async markAttendanceTaken(){
    await this.sendMediasoupControlRequest('attendanceTaken', {username: this.localuserinfo.username});
  }

  async setParticipantLock(participant: Participant){
    return await this.sendMediasoupControlRequest('setParticipantLock', {unlocked: !participant.unlocked, socketid: participant.socketid});
  }

  async sendChatMessage(messagetext: string){
    await this.sendMediasoupControlRequest('chatMessage', { message: messagetext })
  }

  async disconnectUser(remoteId: string){
    await this.sendMediasoupControlRequest('disconnectUser', { remoteId });
  }

  get totalParticipantCount(){
    if(this.meetinginfo!.mode == 'focusmode'){
      return this.allParticipantsCount;//rooms aren't supported in focus mode anyway. so we'll just show the users from all rooms because in focus mode, remote peers aren't transmitted to all users unless they produce media or raise their hand or get unlocked
    }
    else{
      return this.participantsMap.size + 1;
    }
  }
}

export enum NotificationMessages{
  microphonedisabledbyhost = 'microphonedisabledbyhost',
  notyetenabledtospeak = 'notyetenabledtospeak',
  webcamdisabledbyhost = 'webcamdisabledbyhost',
  enabledtospeak = 'enabledtospeak',
  enteredroom = 'enteredroom',
  brokenout = 'brokenout',
  errorproducing = 'errorproducing',
  errorGettingVideoStream = 'errorGettingVideoStream',
  errorGettingAudioStream = 'errorGettingAudioStream',
  maxSpeakingParticipantsReached = 'maxSpeakingParticipantsReached',
  errorSharingScreen = 'errorSharingScreen'
}

export class Locker{
  //We need to make sure that audio/video/screenshare enabling and disabling never run in parallel
  //so i've implemented this locker
  private static promiseMap: Map<object, {promise: Promise<void>, resolver: any}> = new Map<object, {promise: Promise<void>, resolver: any}>()
  static async lock(lock: object){
    if(lock === null || lock === undefined){
      throw new Error('Lock object cannot be null or undefined');
    }

    //wait for any existing promise
    //if the promise is null, await will return immediately
    const existingPromise: Promise<void> | null = this.promiseMap.has(lock) ? this.promiseMap.get(lock)!.promise : null;
    await existingPromise;

    //create a new promise that everyone else will wait on
    //unlocking will have to resolve that promise

    const newPromiseMapItem: {promise: any, resolver: any} = {promise: null, resolver: null};
    const newPromise: Promise<void> = new Promise((resolve, reject) => {
      //promise callbacks are always called synchronously so we know the resolver will be set before we publish this locker item to the map
      newPromiseMapItem.resolver = resolve;  
    });
    newPromiseMapItem.promise = newPromise;
  
    this.promiseMap.set(lock, newPromiseMapItem);
  }

  static unlock(lock: object){
    if(lock === null || lock === undefined){
      throw new Error('Lock object cannot be null or undefined');
    }

    if(!this.promiseMap.has(lock)){
      console.error('No lock has been aquired on object ', lock);
      throw new Error(`No lock has been aquired on specified object: ${lock}`);
    }

    const resolver = this.promiseMap.get(lock)!.resolver;
    resolver();
  }
}

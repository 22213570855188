import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class CloudRecordingService {
  readonly rootURL = environment.meetingServiceRootUrl;
  req_headers =  new HttpHeaders({"content-type": "application/json", "accept" : "application/json"});
  state: CloudRecordingState = CloudRecordingState.idle;//this is the current user's cloud recording state
  secondsToWaitForRecordingToStart = environment.production ? 90 : 90;
  //globalCloudRecordingState: 'idle' | 'requested' | 'recording' = 'idle';//
  constructor(private http: HttpClient, private toastr: ToastrService) { 
  }

  waitForStateChange(currentState, meetingid, jwt, timeoutMs): Promise<void>{
    return new Promise(async (resolve, reject) => {
      const started = new Date().getTime();
      const _requestHasTakenTooLong = () => {
        if(!timeoutMs){
          return false;
        }
        const now = new Date().getTime();
        return now - started > timeoutMs;
      }

      let checkStateTimeout;
      const _checkRecordingStatus = async () => {
        try{
          this.state = await this.checkRecordingStatus(meetingid);
        }
        finally{
          const requestHasTakenTooLong = _requestHasTakenTooLong();
          if(this.state != currentState){
            resolve();
          }
          else{
            if(!requestHasTakenTooLong){
              checkStateTimeout = setTimeout(async () => {
                await _checkRecordingStatus();
              }, 1000);
            }
            else{
              console.error(`recording request has taken over ${timeoutMs}ms. Will cancel the recording request`);
              reject('timeout');
            }
          }
        }
      }

      await _checkRecordingStatus();
    });
  }

  setCurrentState(status: CloudRecordingState){
    this.state = status;
  }

  async requestCloudRecording(meetingid: string, jwt: string){
    await this.http.post<any>(`${this.rootURL}recording/requestrecording`, {meetingid, jwt}).toPromise();
    //this.state = RecordingStatus.requested;
    //setting this.state here is causing the typescript compiler to misbehave
    //so i'll set it using another method
    this.setCurrentState(CloudRecordingState.requested);

    this.toastr.success('Starting Recording. Please wait...');
    //now that I've requested, I need to keep checking until it goes to recording or idle
    //if it hasn't resolved within 30 seconds, then we cancel the request and set it back to idle

    const _failRecording = async () => {
      this.toastr.error('Unable to start recording. Please try again');
      await this.endRecording(meetingid, jwt);//end the recording just in case
      await this.waitForStateChange(CloudRecordingState.requested, meetingid, jwt, undefined);
      //await 
    }
    
    try{
      await this.waitForStateChange(this.state, meetingid, jwt, this.secondsToWaitForRecordingToStart * 1000);
      if(this.state == CloudRecordingState.recording){
        //this.toastr.success('Recording Started');
        //we will show this when the recorder actually joins
      }
      else{
        try{
          //end the recording via api
          await _failRecording();
        }
        catch(error){
          console.error(error);
        }
      }
    }
    catch(error){
      await _failRecording();
    }
  }

  async checkRecordingStatus(meetingid: string): Promise<CloudRecordingState>{
    const ret: {state: CloudRecordingState} = await this.http.get<any>(`${this.rootURL}recording/recordingstatus?meetingid=${meetingid}`).toPromise();
    return ret.state;
  }

  async endRecording(meetingid: string, jwt: string){
    await this.http.post<any>(`${this.rootURL}recording/endrecording`, {meetingid, jwt}).toPromise();
    //this.state = CloudRecordingState.requested;
    this.toastr.success('Stopping Recording...');
    await this.waitForStateChange(CloudRecordingState.recording, meetingid, jwt, undefined);
    //this.toastr.success('Recording stopped');
    //we will show this when we get the message that the recorder has left
    //this.state = RecordingStatus.idle;
  }

  destroy(){
    this.state = CloudRecordingState.idle;
  }
}

export enum CloudRecordingState{
  idle = 'idle',
  requested = 'requested',
  recording = 'recording'
}
